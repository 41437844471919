import React from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useQueryContext } from "../context/QueryContext";
import {
  Button,
  Tooltip,
  Card,
  CardFooter,
  CardHeader,
  Image,
  makeStyles,
  Caption1Stronger,
  Caption1,
  Menu,
  MenuTrigger,
  MenuButton,
  MenuPopover,
  MenuList,
  MenuItem,
  Input,
} from "@fluentui/react-components";
import {
  ArrowSyncRegular,
  ChatFilled,
  CopyRegular,
  EditFilled,
  ChevronDownFilled,
  DeleteFilled,
  MoreVerticalFilled,
  ChevronUpFilled,
  SearchFilled,
  ArrowSortFilled,
} from "@fluentui/react-icons";
import { goToQueryResult, handleReportQuery, onRemove, showParams } from "../../utilities/excel";
import { useDialog } from "../context/DialogContext";
import RunningDialog from "./RunningDialog";
import QueryInfoExpand from "./QueryInfoExpand";
import { useQueryRefresh } from "../../utilities/useQueryRefresh";

const useStyles = makeStyles({
  queriesContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    gap: "20px",
  },
  queryCard: {
    borderRadius: "10px",
    backgroundColor: "#e3e1e2",
    border: "1px solid rgba(0, 0, 0, .125)",
  },
  selectedQueryItem: {
    borderRadius: "10px",
    border: "1px solid #B521CF",
    backgroundColor: "#F5D6F9",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  textOverflow: {
    display: "inline-block",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  searchInput: { display: "flex", flexDirection: "row", gap: "10px" },
});

const QueryList = ({ selectedQueryId, setIsloading }) => {
  const token = localStorage.getItem("DS-TOKEN");
  const userName = localStorage.getItem("DS-EXCEL-USERNAME");
  const [expandedQueryId, setExpandedQueryId] = React.useState(null);
  const [filteredQueries, setFilteredQueries] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const navigate = useNavigate();

  const { dialogOpen, setDialogOpen } = useDialog();

  const styles = useStyles();
  const { queries, removeQuery, loadQueriesFromSheet, setSelectedQueryId, setIsDuplicating } = useQueryContext();

  const onRemoveQuery = (queryUUID) => {
    mutation.mutate(queryUUID);
  };

  const mutation = useMutation(onRemove, {
    onMutate: () => {
      setIsloading(true);
    },
    onSuccess: (queryUUID) => {
      removeQuery(queryUUID);
      setIsloading(false);
    },
    onError: (error, queryUUID) => {
      setIsloading(false);
    },
  });

  const { handleRefresh } = useQueryRefresh(setDialogOpen, loadQueriesFromSheet, token, userName);

  const sortByProperty = (property, order = "asc", isDate = false) => {
    return (a, b) => {
      const valueA = isDate ? new Date(a[property]) : a[property];
      const valueB = isDate ? new Date(b[property]) : b[property];

      if (valueA < valueB) {
        return order === "asc" ? -1 : 1;
      }
      if (valueA > valueB) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    };
  };

  const sortByLastUpdatedAZ = (data) => [...data].sort(sortByProperty("updated", "asc", true));
  const sortByLastUpdatedZA = (data) => [...data].sort(sortByProperty("updated", "desc", true));

  const sortByCreatedAZ = (data) => [...data].sort(sortByProperty("created", "asc", true));
  const sortByCreatedZA = (data) => [...data].sort(sortByProperty("created", "desc", true));

  const sortByConnectorAZ = (data) => [...data].sort(sortByProperty("connector", "asc"));
  const sortByConnectorZA = (data) => [...data].sort(sortByProperty("connector", "desc"));

  React.useEffect(() => {
    setFilteredQueries(queries);
  }, [queries]);

  const objectToString = (obj) => {
    let result = "";
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        result += objectToString(obj[key]);
      } else {
        result += String(obj[key]);
      }
    }
    return result.toLowerCase();
  };

  React.useEffect(() => {
    const filtered = queries.filter((query) => {
      const queryString = objectToString(query);
      return queryString.includes(searchTerm.toLowerCase());
    });
    setFilteredQueries(filtered);
  }, [searchTerm, queries]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSort = (sortFunction) => {
    const sortedData = sortFunction(filteredQueries);
    setFilteredQueries(sortedData);
  };
  return (
    <>
      <RunningDialog dialogOpen={dialogOpen} />
      <div className={styles.queriesContainer}>
        <div className={styles.searchInput}>
          <Input
            placeholder="Search query"
            contentBefore={<SearchFilled />}
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full"
          />
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <Tooltip content="With calendar icon and no contents" relationship="label">
                <MenuButton appearance="transparent" icon={<ArrowSortFilled />} />
              </Tooltip>
            </MenuTrigger>
            <MenuPopover>
              <MenuList>
                <MenuItem onClick={() => handleSort(sortByLastUpdatedAZ)}>Sort by last updated A → Z</MenuItem>
                <MenuItem onClick={() => handleSort(sortByLastUpdatedZA)}>Sort by last updated Z → A</MenuItem>
                <MenuItem onClick={() => handleSort(sortByCreatedAZ)}>Sort by created A → Z</MenuItem>
                <MenuItem onClick={() => handleSort(sortByCreatedZA)}>Sort by created Z → A</MenuItem>
                <MenuItem onClick={() => handleSort(sortByConnectorAZ)}>Sort by connector A → Z</MenuItem>
                <MenuItem onClick={() => handleSort(sortByConnectorZA)}>Sort by connector Z → A</MenuItem>
              </MenuList>
            </MenuPopover>
          </Menu>
        </div>
        {filteredQueries &&
          filteredQueries.map((query, index) => (
            <Card
              className={query.queryUUID === selectedQueryId ? styles.selectedQueryItem : styles.queryCard}
              key={query.queryUUID}
            >
              <CardHeader
                header={
                  <div className={styles.cardHeader}>
                    <Caption1Stronger>
                      {query.blendName ? query.blendName.toUpperCase() : query.dataSource.toUpperCase()} QUERY
                    </Caption1Stronger>
                    <Image
                      className={styles.logoImg}
                      alt={`logo connector ${index + 1}`}
                      src={
                        query.dataSource.startsWith("blend_")
                          ? "../assets/blend-dark.png"
                          : `../assets/${query.dataSource}.png`
                      }
                      height={20}
                      width={20}
                    />
                  </div>
                }
                action={
                  <Menu>
                    <MenuTrigger disableButtonEnhancement>
                      <MenuButton appearance="transparent" icon={<MoreVerticalFilled />} />
                    </MenuTrigger>
                    <MenuPopover>
                      <MenuList>
                        <MenuItem onClick={() => showParams(query.queryUUID)}>Show params</MenuItem>
                        <MenuItem onClick={() => goToQueryResult(query.queryUUID)}>Go to results</MenuItem>
                        <MenuItem onClick={() => onRemoveQuery(query.queryUUID)}>Delete query</MenuItem>
                      </MenuList>
                    </MenuPopover>
                  </Menu>
                }
              />
              <div className="flex flex-col">
                <Caption1 className={styles.textOverflow}>
                  Accounts:{" "}
                  {JSON.parse(query.accountsViews)
                    .map((account) => {
                      if (account.name) {
                        return account.name;
                      } else if (account.usernames) {
                        return account.usernames;
                      } else if (account.hashtags) {
                        return account.hashtags;
                      }
                      return "";
                    })
                    .join(", ")}
                </Caption1>
                <Caption1 className={styles.textOverflow}>
                  Connected with: {query.refreshWithUserAccount.toString()}
                </Caption1>
                <Caption1 className={styles.textOverflow}>Updated: {query.updated || "--"}</Caption1>
              </div>
              {expandedQueryId === query.queryUUID && <QueryInfoExpand queryInfo={query} />}
              <CardFooter className="flex justify-between">
                <div>
                  <Tooltip content="Refresh query" relationship="label" positioning="below">
                    <Button
                      onClick={() => handleRefresh(query, queries)}
                      appearance="transparent"
                      icon={<ArrowSyncRegular />}
                    />
                  </Tooltip>
                  <Tooltip content="Modify query" relationship="label" positioning="below">
                    <Button
                      onClick={() => {
                        setSelectedQueryId(query.queryUUID);
                        setIsDuplicating(false);
                        navigate("/add-query");
                      }}
                      appearance="transparent"
                      icon={<EditFilled />}
                    />
                  </Tooltip>
                  <Tooltip content="Duplicate query" relationship="label" positioning="below">
                    <Button
                      onClick={() => {
                        setSelectedQueryId(query.queryUUID);
                        setIsDuplicating(true);
                        navigate("/add-query");
                      }}
                      appearance="transparent"
                      icon={<CopyRegular />}
                    />
                  </Tooltip>
                  <Tooltip content="Report query" relationship="label" positioning="below">
                    <Button
                      onClick={() => handleReportQuery(token, query)}
                      appearance="transparent"
                      icon={<ChatFilled />}
                    />
                  </Tooltip>
                </div>
                <div>
                  <Tooltip content="Delete query & data" relationship="label" positioning="below">
                    <Button
                      appearance="transparent"
                      icon={<DeleteFilled />}
                      onClick={() => onRemoveQuery(query.queryUUID)}
                    />
                  </Tooltip>
                  <Button
                    appearance="transparent"
                    icon={expandedQueryId === query.queryUUID ? <ChevronUpFilled /> : <ChevronDownFilled />}
                    onClick={() => setExpandedQueryId(expandedQueryId === query.queryUUID ? null : query.queryUUID)}
                  />
                </div>
              </CardFooter>
            </Card>
          ))}
      </div>
    </>
  );
};

export default QueryList;
