import { fetchAPI } from "./base";

export function getDStoken({ token, username }) {
  return fetchAPI({
    endpoint: `microsoft/login`,
    token,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token, username }),
  });
}

export function getUserInfo({ token, service_id }) {
  return fetchAPI({
    endpoint: `microsoft/user-info?service_id=${service_id}`,
    headers: {
      token,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
}

export function registerUser(body, service_id) {
  return fetchAPI({
    endpoint: `microsoft/register?service_id=${service_id}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  });
}
