import React, { Component } from "react";
import { Button, Text, Image } from "@fluentui/react-components";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error", error, errorInfo);
    if (this.props.onError) {
      this.props.onError(error, errorInfo);
    }

    const containerElement = document.getElementById("container");
    if (containerElement) {
      containerElement.removeAttribute("aria-hidden");
    }
  }

  handleReload = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-container">
          <div id="start-body" className="start-body">
            <div>
              <Image alt="Dataslayer Logo" src="../assets/dataslayer-logo.svg" height={50} width={200} />
            </div>
            <div className="flex flex-col items-center">
              <span className="error-text">An error has occurred</span>
              <Text variant="medium" style={{ marginBottom: "20px" }}>
                We have sent a report to our team, and they will look into the issue. Thank you for your patience!
              </Text>
              <Text className="error-explain">You can reload the page</Text>
            </div>
            <div className="flex gap-2 w-full">
              {/* <Button className="w-full" appearance="secondary" onClick={this.handleRedirectHome}>
                Go to Homepage
              </Button> */}
              <Button className="w-full" appearance="primary" onClick={this.handleReload}>
                Reload Page
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
