import React from "react";
import { useMutation } from "react-query";
import { cleanDate } from "./formatDates";
import { getUserTimeZone, showDialog, updateQuerySheet } from "./excel";
import { config } from "../Constants";
import { generateBlendTable, generateTable, getAccountsByTaskId } from "../services/connector";
import { useQueryContext } from "../taskpane/context/QueryContext";
import { getFileWebUrlById, syncWithOneDrive } from "./office-apis-helpers";
import { getAllSchedules } from "../services/actions";
import { useGenerateTable } from "./useGenerateTable";

export const useQueryRefresh = (
  setDialogOpen = () => {},
  loadQueriesFromSheet = () => {},
  token = "",
  userName = "",
  updateMessage = () => {}
) => {
  const { checkAvailableQueries } = useGenerateTable(null, token, userName, showDialog, null, setDialogOpen);
  const { setProgress } = useQueryContext();
  const startTimeRef = React.useRef(null);
  const { refreshStatusQuery } = useQueryContext();
  const handleRefresh = async (query, queries) => {
    try {
      setDialogOpen(true);
      const queryData = getQueryConfigById(query, queries);
      const requestData = transformQueryDataForAPI(queryData);
      const type = requestData.dataSource.startsWith("blend") ? "blend" : "normal";
      const result = await checkAvailableQueries(token, type, "refresh", requestData);
      if (!result || !result.ok) return;

      const taskId = result.task_id;
      if (taskId) {
        const data = await fetchTableData(taskId);
        if (data) {
          const executionTime = ((performance.now() - startTimeRef.current) / 1000).toFixed(2);
          updateQuerySheet(query, executionTime, refreshStatusQuery, data.result);
        }
      }
    } catch (err) {
      showDialog("Error", ["Failed to refresh query"]);
    } finally {
      setDialogOpen(false);
      loadQueriesFromSheet();
    }
  };

  const handleRefreshAll = async (queries) => {
    setProgress({ completed: 0, total: queries.length });
    setDialogOpen(true);

    for (const query of queries) {
      await handleRefresh(query, queries);
      setProgress((prevProgress) => ({
        ...prevProgress,
        completed: prevProgress.completed + 1,
      }));
    }

    setDialogOpen(false);
    setProgress({ completed: 0, total: 0 });
  };

  // Función para obtener datos de la tabla usando el task_id
  const fetchTableData = async (taskId) => {
    try {
      const response = await getAccountsByTaskId({ taskId });
      if (response?.ok) {
        const result = await response.json();
        if (result.finished) {
          return result;
        } else {
          return new Promise((resolve) => setTimeout(async () => resolve(await fetchTableData(taskId)), 3000));
        }
      } else {
        throw new Error("Error fetching accounts");
      }
    } catch (error) {
      showDialog("Warning message", "Failed to fetch accounts");
      return null;
    }
  };

  function transformQueryDataForAPI(queryData) {
    const isBlendConnector = queryData.dataSource.startsWith("blend");
    return {
      ...queryData,
      cache: true,
      dataSource: queryData.dataSource,
      dates: {
        end_date: cleanDate(queryData.endDate),
        start_date: cleanDate(queryData.startDate),
        date_range_type: queryData.dateRangeType,
      },
      timezone: getUserTimeZone(),
      accounts: JSON.parse(queryData.accountsViews),
      accountsViews: JSON.parse(queryData.accountsViews),
      call_type: queryData.reportType,
      page_ids: queryData.accountsViews
        ? Array.isArray(JSON.parse(queryData.accountsViews)) &&
          JSON.parse(queryData.accountsViews)[0]?.hasOwnProperty("page_ids")
          ? JSON.parse(queryData.accountsViews)[0].page_ids
          : ""
        : "",
      metrics: isBlendConnector
        ? JSON.parse(queryData.metrics)
        : JSON.parse(queryData.metrics).map((metric) => metric.id),
      pivotDimensions: JSON.parse(queryData.pivotDimensions),
      dims: JSON.parse(queryData.dimensions),
      filters: JSON.parse(queryData.filters),
      refreshWithUserAccount: JSON.parse(queryData.refreshWithUserAccount),
      sort_by: JSON.parse(queryData.sort),
      sort_dims_by: JSON.parse(queryData.sortDimensions),
      options: {},
      high_priority: true,
      compare_dates: {},
      service_id: config.SERVICE_ID,
      connections: JSON.parse(queryData.refreshWithUserAccount),
      dimensions: {
        rows: isBlendConnector
          ? JSON.parse(queryData.dimensions)
          : JSON.parse(queryData.dimensions).map((dim) => dim.id),

        cols: isBlendConnector
          ? JSON.parse(queryData.pivotDimensions)
          : JSON.parse(queryData.pivotDimensions).map((dim) => dim.id),

        limit_rows: queryData.maxRows || 0,
        limit_cols: queryData.maxPivotCategories || 0,
      },
      sheet_name: JSON.parse(queryData.otherParameters).sheet_name || "",
      sheet_range: JSON.parse(queryData.otherParameters).sheet_range || "",
      document_url: JSON.parse(queryData.otherParameters).document_url || "",
      number_of_posts: queryData.otherParameters?.number_of_posts ?? "",
      num_posts_by_hashtag: queryData.otherParameters?.num_posts_by_hashtag ?? 0,
      post_search_type: queryData.otherParameters?.post_search_type ?? "",
      hashtags: queryData.accountsViews
        ? Array.isArray(JSON.parse(queryData.accountsViews)) &&
          JSON.parse(queryData.accountsViews)[0]?.hasOwnProperty("hashtags")
          ? JSON.parse(queryData.accountsViews)[0].hashtags
          : ""
        : "",
      search_term: queryData.otherParameters?.search_term ?? "",
      result_type_related: "",
      region: queryData.otherParameters?.region ?? "",
      gprop: queryData.otherParameters?.gprop ?? "",
      type: queryData.otherParameters?.type ?? "",
      category: queryData.otherParameters?.category ?? "",
      year: queryData.otherParameters?.year ?? "",
      segments: [],
      segment_dim: "",
      search_types: queryData.otherParameters?.search_types ?? [],
      country: queryData.otherParameters?.country ?? "",
      keywords: queryData.otherParameters?.keywords || "",
      language: queryData.otherParameters?.language || "",
      keyword_plan_network: queryData.otherParameters?.keyword_plan_network || 0,
      page_url: queryData.otherParameters?.page_url || "",
      include_adult_keywords: queryData.otherParameters?.include_adult_keywords || false,
      url: queryData.otherParameters?.url ?? "",
      url_type: queryData.otherParameters?.url_type ?? "",
      json_path: queryData.otherParameters?.json_path ?? "",
      headers: queryData.otherParameters?.headers ?? "",
      request: queryData.otherParameters?.request ?? "",
      query_type: queryData.otherParameters?.query_type ?? "",
      usernames: queryData.accountsViews
        ? Array.isArray(JSON.parse(queryData.accountsViews)) &&
          JSON.parse(queryData.accountsViews)[0]?.hasOwnProperty("usernames")
          ? JSON.parse(queryData.accountsViews)[0].usernames
          : ""
        : "",
    };
  }

  function getQueryConfigById(queryId, queries) {
    return queries.find((query) => query.queryUUID === queryId.queryUUID);
  }

  const handleRefreshActiveSheetQueries = async (queries) => {
    try {
      await Excel.run(async (context) => {
        const activeSheet = context.workbook.worksheets.getActiveWorksheet();
        activeSheet.load("name");
        await context.sync();

        const activeSheetName = activeSheet.name;
        const dataslayerQueriesSheet = context.workbook.worksheets.getItem("DataslayerQueries");
        const range = dataslayerQueriesSheet.getUsedRange();
        range.load("values");
        await context.sync();

        const matchingQueries = range.values.filter((row) => row[1] === activeSheetName);

        const activeSheetQueryIds = matchingQueries.map((row) => row[0]);
        const queriesToRefresh = queries.filter((query) => activeSheetQueryIds.includes(query.queryUUID));

        for (const query of queriesToRefresh) {
          await handleRefresh(query, queries);
        }
      });
    } catch (error) {
      showDialog("Error", "Failed to refresh active sheet queries");
    }
  };

  const getSchedulesMutation = useMutation(
    async () => {
      try {
        const onedriveFile = await syncWithOneDrive(updateMessage);
        if (onedriveFile && userName) {
          const webUrl = await getFileWebUrlById(localStorage.getItem("DS-EXCEL-TOKEN"), onedriveFile.fileId);
          const response = await getAllSchedules({
            user_email: userName,
            service_id: config.SERVICE_ID,
            document_url: webUrl,
            // document_url: `https://graph.microsoft.com/v1.0/me/drive/items/${onedriveFile.fileId}/content`,
          });

          if (response?.ok) {
            return await response.json();
          }
        }
      } catch (error) {
        throw new Error("Error during schedule refresh");
      }
    },
    {
      onError: (error) => {
        showDialog("Error", [`Failed to refresh schedules: ${error.message}`]);
      },
    }
  );

  return {
    handleRefresh,
    handleRefreshAll,
    handleRefreshActiveSheetQueries,
    getSchedulesRefetch: getSchedulesMutation.mutateAsync,
  };
};
