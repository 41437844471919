import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Input,
  useId,
  DialogSurface,
  DialogBody,
  makeStyles,
  Select,
  Field,
  Checkbox,
  Label,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import {
  calculatePreviousPeriod,
  calculateSameDatesAYearAgo,
  calculateSameDatesAYearAgoMatchingDay,
  calculateDateRange,
  onFormatDate,
  isValidDate,
  cleanDate,
} from "../../utilities/formatDates";
import { dateRangeOptions, compareToOptions, comparisonTypeOptions } from "./../../utilities/datesFilterConstants";
import CustomDatePicker from "./CustomDatePicker";

const useStyles = makeStyles({
  dialogWidth: {
    width: "80%",
    maxWidth: "500px",
    margin: "auto",
  },
  dialogBody: {
    display: "flex!important",
    flexDirection: "column!important",
  },
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  fieldDatePicker: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
  },
  container: {
    margin: "10px 0",
  },
});

const DateRangeDialog = ({
  open,
  handleClose,
  handleCompareDates,
  handleDiscard,
  onDateSelect,
  selectedDates,
  selectedCustomDateRange,
  selectedIncludeCurrent,
  selectedCustomXValue,
  setSelectedCustomDateRange,
  setSelectedIncludeCurrent,
  setSelectedCustomXValue,
}) => {
  const styles = useStyles();
  const selectId = useId();
  const today = new Date();
  const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
  const [selectedDateOptionId, setSelectedDateOptionId] = React.useState(selectedCustomDateRange || dateRangeOptions[15].value);
  const [selectedDateOptionName, setSelectedDateOptionName] = React.useState(() => {
    const foundOption = dateRangeOptions.find(option => option.value === selectedCustomDateRange);
    return foundOption ? foundOption.name : dateRangeOptions[15].name;
  });
  const [selectedCompareOption, setSelectedCompareOption] = React.useState(compareToOptions[0].value);
  const [selectedCompareOptionName, setSelectedCompareOptionName] = React.useState(compareToOptions[0].name);
  const [comparisonType, setComparisonType] = React.useState(comparisonTypeOptions[0].value);
  const [startDate, setStartDate] = React.useState(
    selectedDates && selectedDates.start_date ? selectedDates.start_date : firstDayOfYear
  );
  const [endDate, setEndDate] = React.useState(
    selectedDates && selectedDates.end_date ? selectedDates.end_date : today
  );
  const [comparisonDates, setComparisonDates] = React.useState({ start: null, end: null });
  const onCalculateComparisonDates = (type, startDate, endDate) => {
    const start_date = !isValidDate(startDate) ? new Date(startDate) : startDate;
    const end_date = !isValidDate(endDate) ? new Date(endDate) : endDate;
    if (start_date && end_date) {
      let dates;
      switch (type) {
        case "prev_period":
          dates = calculatePreviousPeriod(start_date, end_date);
          break;
        case "last_year":
          dates = calculateSameDatesAYearAgo(start_date, end_date);
          break;
        case "last_year_match_weekday":
          dates = calculateSameDatesAYearAgoMatchingDay(start_date, end_date);
          break;
        case "custom":
          dates = { newStartDate: firstDayOfYear, newEndDate: today };
          break;
        default:
          dates = { newStartDate: null, newEndDate: null };
          break;
      }
      setComparisonDates({ start: dates.newStartDate, end: dates.newEndDate });
    } else {
      setComparisonDates({});
    }
  };
  const handleChangeDate = (event) => {
    const selectedId = event.target.value;
    const selectedOption = dateRangeOptions.find((option) => option.value === selectedId);
    if (selectedOption) {
      setSelectedDateOptionId(selectedOption.value);
      setSelectedDateOptionName(selectedOption.name);
    }

    const { startDate: newStartDate, endDate: newEndDate } = calculateDateRange(
      selectedId,
      selectedCustomXValue,
      selectedIncludeCurrent,
      selectedDates
    );

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    onCalculateComparisonDates(selectedCompareOption, newStartDate, newEndDate);
  };

  const updateDates = () => {
    const { startDate, endDate } = calculateDateRange(
      selectedDateOptionId,
      selectedCustomXValue,
      selectedIncludeCurrent,
      selectedDates
    );
    setStartDate(startDate);
    setEndDate(endDate);
  };

  React.useEffect(() => {
    updateDates();
  }, [selectedCustomXValue, selectedIncludeCurrent, selectedDateOptionId]);

  React.useEffect(() => {
    if (selectedCompareOption !== "none") {
      onCalculateComparisonDates(selectedCompareOption, startDate, endDate);
    }
  }, [selectedCompareOption, startDate, endDate]);

  React.useEffect(() => {
    if (selectedDates) {
      setStartDate(selectedDates.start_date ? selectedDates.start_date : firstDayOfYear);
      setEndDate(selectedDates.end_date ? selectedDates.end_date : today);
      setSelectedDateOptionId(
        selectedCustomDateRange ? selectedCustomDateRange : dateRangeOptions[15].value
      );
      setSelectedDateOptionName(() => {
        const foundOption = dateRangeOptions.find(option => option.value === selectedCustomDateRange);
        return foundOption ? foundOption.name : dateRangeOptions[15].name;
      });
      setSelectedCustomXValue(selectedCustomXValue || 1);
      setSelectedIncludeCurrent(selectedIncludeCurrent || false);
    }
  }, [selectedDates]);

  const handleChangeCompare = (event) => {
    const selectedId = event.target.value;
    const selectedOption = compareToOptions.find((option) => option.value === selectedId);
    if (selectedOption) {
      setSelectedCompareOption(selectedOption.value);
      setSelectedCompareOptionName(selectedOption.name);
    }
    onCalculateComparisonDates(selectedOption.value, startDate, endDate);
  };

  const getLabelForOption = (isSingular = false) => {
    if (selectedDateOptionName.includes("day")) {
      return isSingular ? "day" : "days";
    } else if (selectedDateOptionName.includes("week")) {
      return isSingular ? "week" : "weeks";
    } else if (selectedDateOptionName.includes("month")) {
      return isSingular ? "month" : "months";
    } else if (selectedDateOptionName.includes("year")) {
      return isSingular ? "year" : "years";
    } else {
      return "";
    }
  };
  const renderCustomInput = () => {
    if (
      ["Last X days", "Last X weeks (Sun-Sat)", "Last X weeks (Mon-Sun)", "Last X months", "Last X years"].includes(
        selectedDateOptionName
      )
    ) {
      return (
        <div className={styles.container}>
          <Label>Last</Label>
          <Input
            type="number"
            value={selectedCustomXValue}
            onChange={(e) => setSelectedCustomXValue(e.target.value)}
            style={{ width: "80px", marginRight: "5px", marginLeft: "5px" }}
          />
          <Label>{getLabelForOption()}</Label>
          <Checkbox
            label={`including this ${getLabelForOption(true)}`}
            checked={selectedIncludeCurrent}
            onChange={(e) => setSelectedIncludeCurrent(e.target.checked)}
          />
        </div>
      );
    }
    return null;
  };

  const renderComparisonFields = () => {
    if (selectedCompareOptionName !== "None") {
      return (
        <div className={styles.container}>
          <Label>Comparison type</Label>
          <Select value={comparisonType} onChange={(e) => setComparisonType(e.target.value)}>
            {comparisonTypeOptions.map((option) => (
              <option key={option.id} value={option.value}>
                {option.name}
              </option>
            ))}
          </Select>

          <div className="flex justify-between my-3">
            <Field id="custom-date-picker" className={styles.fieldDatePicker} label="Start">
              <CustomDatePicker
                onSelectDate={(date) => {
                  setComparisonDates({ start: date, end: comparisonDates.end });
                }}
                selectedDate={comparisonDates.start}
              />
            </Field>

            <Field id="custom-date-picker" className={styles.fieldDatePicker} label="End">
              <CustomDatePicker
                onSelectDate={(date) => {
                  setComparisonDates({ start: comparisonDates.start, end: date });
                }}
                selectedDate={comparisonDates.end}
              />
            </Field>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <Dialog open={open}>
      <DialogSurface className={styles.dialogWidth}>
        <DialogBody className={styles.dialogBody}>
          <DialogTitle className={styles.dialogTitle}>
            Dates
            <div className="gap-3 flex">
              <Button
                appearance="primary"
                size="small"
                onClick={() => {
                  if (startDate && endDate) {
                    const dates = {
                      date_range_type: selectedDateOptionId,
                      start_date: isValidDate(startDate) ? onFormatDate(startDate) : cleanDate(startDate),
                      end_date: isValidDate(endDate) ? onFormatDate(endDate) : cleanDate(endDate),
                    };
                    onDateSelect(dates);
                  }
                  if (selectedCompareOption === "none") {
                    handleCompareDates({});
                  } else {
                    handleCompareDates({
                      to: selectedCompareOption,
                      type: comparisonType,
                      start_date: new Date(comparisonDates.start),
                      end_date: new Date(comparisonDates.end),
                    });
                  }
                }}
              >
                Apply
              </Button>
              <Button size="small" onClick={handleDiscard}>
                Discard
              </Button>
            </div>
          </DialogTitle>
          <DialogContent>
            <Select value={selectedDateOptionId} onChange={handleChangeDate}>
              {dateRangeOptions.map((option) => (
                <option key={option.id} value={option.value}>
                  {option.name}
                </option>
              ))}
            </Select>
            {renderCustomInput()}
            <div className="flex justify-between my-3">
              <Field id="custom-date-picker" className={styles.fieldDatePicker} label="Start">
                <CustomDatePicker
                  onSelectDate={(date) => {
                    setStartDate(date);
                  }}
                  selectedDate={isValidDate(startDate) ? onFormatDate(startDate) : cleanDate(startDate)}
                  // selectedDate={selectedDates.start_date}
                />
              </Field>

              <Field id="custom-date-picker" className={styles.fieldDatePicker} label="End">
                <CustomDatePicker
                  onSelectDate={(date) => {
                    setEndDate(date);
                  }}
                  selectedDate={isValidDate(endDate) ? onFormatDate(endDate) : cleanDate(endDate)}
                />
              </Field>
            </div>
            <label htmlFor={selectId}>Compare to</label>
            <Select id={selectId} value={selectedCompareOption} onChange={handleChangeCompare}>
              {compareToOptions.map((option) => (
                <option key={option.id} value={option.value}>
                  {option.name}
                </option>
              ))}
            </Select>
            {renderComparisonFields()}
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default DateRangeDialog;
