import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import { FluentProvider } from "@fluentui/react-components";
import "./main.css";
import { lightTheme } from "./theme";
import { QueryClient, QueryClientProvider } from "react-query";
import { DialogProvider } from "./context/DialogContext";
import { createHiddenSheet } from "../utilities/excel";
import { QueryProvider } from "./context/QueryContext";
import ErrorBoundary from "./components/ErrorBoundary";
import { MessageProvider } from "./context/MessageContext";

const queryClient = new QueryClient();
window.queryClient = queryClient;

const title = "Dataslayer Task Pane Add-in";
const rootElement = document.getElementById("container");
const root = createRoot(rootElement);

/* Render application after Office initializes */
Office.onReady(async () => {
  await Excel.run(async (context) => {
    await createHiddenSheet(context);
  });

  root.render(
    <QueryClientProvider client={queryClient}>
      <FluentProvider theme={lightTheme}>
        <DialogProvider>
          <QueryProvider>
            <MessageProvider>
              <ErrorBoundary>
                <App title={title} />
              </ErrorBoundary>
            </MessageProvider>
          </QueryProvider>
        </DialogProvider>
      </FluentProvider>
    </QueryClientProvider>
  );
});

if (module.hot) {
  module.hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    root.render(NextApp);
  });
}

Office.actions.associate("manageQueriesAction", (event) => {
  const newEvent = new CustomEvent("ribbonButtonClick", { detail: { action: "manageQueries" } });
  window.dispatchEvent(newEvent);
  event.completed();
});

Office.actions.associate("refreshAllQueriesAction", (event) => {
  const refreshEvent = new CustomEvent("ribbonButtonClick", { detail: { action: "refreshAllQueries" } });
  window.dispatchEvent(refreshEvent);
  event.completed();
});

Office.actions.associate("refreshActiveSheetAction", (event) => {
  const activeSheetEvent = new CustomEvent("ribbonButtonClick", { detail: { action: "refreshActiveSheet" } });
  window.dispatchEvent(activeSheetEvent);
  event.completed();
});

Office.actions.associate("duplicateFileAction", (event) => {
  const activeSheetEvent = new CustomEvent("ribbonButtonClick", { detail: { action: "duplicateFile" } });
  window.dispatchEvent(activeSheetEvent);
  event.completed();
});

Office.actions.associate("templateGalleryAction", (event) => {
  const activeSheetEvent = new CustomEvent("ribbonButtonClick", { detail: { action: "templateGallery" } });
  window.dispatchEvent(activeSheetEvent);
  event.completed();
});

Office.actions.associate("gettingStartedAction", (event) => {
  const activeSheetEvent = new CustomEvent("ribbonButtonClick", { detail: { action: "gettingStarted" } });
  window.dispatchEvent(activeSheetEvent);
  event.completed();
});
