/**
 * Encuentra un grupo de cabeceras en un array.
 */
export function findGroupInArray(group) {
  return function (item) {
    if (item.length !== group.length) return false;
    for (let i = 0; i < group.length; i++) {
      if (item[i] !== group[i]) return false;
    }
    return true;
  };
}

/**
 * Construye una lista de grupos de cabeceras a partir de la primera fila de la tabla.
 */
export function buildListGroupsHeaders(table, numRowDimensions, numColumnDimensions, numMetrics) {
  if (table.length > 0) {
    let headerGroup = table[0].slice(0, numRowDimensions + numColumnDimensions);
    return [headerGroup];
  }
  return [];
}

/**
 * Crea una estructura (índice) para ubicar filas en oldValues
 */
export function createOldValuesStructure(oldValues, numDimensions) {
  let structure = {};
  oldValues.forEach((row, index) => {
    let key = row.slice(0, numDimensions).join("|");
    structure[key] = index;
  });
  return structure;
}

/**
 * Combina newValues con la estructura de oldValues.
 */
export function createNewValuesStructure(newValues, numDimensions, oldValuesStructure, oldValues) {
  let result = oldValues.slice(); // clonamos
  newValues.forEach((row) => {
    let key = row.slice(0, numDimensions).join("|");
    if (oldValuesStructure.hasOwnProperty(key)) {
      let oldRowIndex = oldValuesStructure[key];
      let oldRow = result[oldRowIndex];
      for (let i = numDimensions; i < row.length; i++) {
        oldRow[i] = row[i];
      }
      result[oldRowIndex] = oldRow;
    } else {
      result.push(row);
    }
  });
  return result;
}

/**
 * Invierte los ejes de la tabla (transpone filas y columnas).
 */
export function invertTableAxis(table) {
  return table[0].map((_, colIndex) => table.map((row) => row[colIndex]));
}

/**
 * Agrega un “nuevo grupo de cabeceras” a la tabla antigua.
 */
export function addNewGroup(oldValues, group, numOfColumnDimensions, numOfMetrics) {
  let newValues = [];
  oldValues.forEach((row, index) => {
    if (index === 0) {
      // Fila de cabecera => concatenar todo el “group”
      newValues.push(row.concat(group));
    } else {
      // Resto de filas => agregamos celdas vacías
      let emptyCells = new Array(group.length).fill("");
      newValues.push(row.concat(emptyCells));
    }
  });
  return newValues;
}

/**
 * Combina tablas cuando hay dimensiones tanto en filas como en columnas.
 */
export function combineRowAndColumnTables(
  oldValues,
  newValues,
  numOfRowDimensions,
  numOfColumnDimensions,
  numOfMetrics
) {
  const header = oldValues[0].slice();

  const oldData = oldValues.slice(1).map((row) => row.slice());
  const newData = newValues.slice(1).map((row) => row.slice());
  let oldValuesStructure = createOldValuesStructure(oldData, numOfRowDimensions);
  let combinedData = createNewValuesStructure(newData, numOfRowDimensions, oldValuesStructure, oldData);

  return [header].concat(combinedData);
}

/**
 * Combina tablas cuando solo hay dimensiones en filas.
 */
export function combineRowTables(oldValues, newValues, numOfRowDimensions) {
  const header = oldValues[0].slice();

  const oldData = oldValues.slice(1).map((row) => row.slice());
  const newData = newValues.slice(1).map((row) => row.slice());

  let oldValuesStructure = createOldValuesStructure(oldData, numOfRowDimensions);

  let combinedData = createNewValuesStructure(newData, numOfRowDimensions, oldValuesStructure, oldData);

  return [header].concat(combinedData);
}

/**
 * Combina tablas cuando solo hay dimensiones en columnas.
 */
export function combineColumnTables(oldValues, newValues, numOfColumnDimensions) {
  let colNewValues = invertTableAxis(newValues);
  let colOldValues = invertTableAxis(oldValues);

  let oldValuesStructure = createOldValuesStructure(colOldValues, numOfColumnDimensions);
  colOldValues = createNewValuesStructure(colNewValues, numOfColumnDimensions, oldValuesStructure, colOldValues);

  let newTable = invertTableAxis(colOldValues);
  return newTable;
}
