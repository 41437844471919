import React from "react";
import { Routes, Route } from "react-router-dom";
import QueryManager from "./QueryManager";
import MainAccordion from "./MainAccordion";
import ScheduleManager from "./ScheduleManager";

const Content = ({ loginInfo, setLoginInfo, setSecret, setOneDriveAuth }) => {
  return (
    <div id="main-content" className="mb-24">
      <Routes>
        <Route exact path="/" element={<QueryManager setLoginInfo={setLoginInfo} />} />
        <Route path="/authorize" element={<div>Hola mundo</div>} />
        <Route
          path="add-query"
          element={<MainAccordion title="CONNECTORS" loginInfo={loginInfo} setLoginInfo={setLoginInfo} />}
        />
        <Route
          exact
          path="schedules"
          element={<ScheduleManager setSecret={setSecret} setOneDriveAuth={setOneDriveAuth} />}
        />
      </Routes>
    </div>
  );
};

export default Content;
