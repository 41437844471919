import { ensureValidAccessToken } from "./auth";
import { fetchAPI } from "./base";

export async function getDStoken({ token, username }) {
  const validToken = await ensureValidAccessToken();
  return fetchAPI({
    endpoint: `microsoft/login`,
    token: validToken,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token: validToken, username }),
  });
}

export function getUserInfo({ token, service_id }) {
  return fetchAPI({
    endpoint: `microsoft/user-info?service_id=${service_id}`,
    headers: {
      token,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
}

export function getUserToken({ code, signal }) {
  return fetchAPI({
    endpoint: `microsoft/auth/check?secret=${code}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    signal,
  });
}

export function registerUser(body, service_id) {
  return fetchAPI({
    endpoint: `microsoft/register?service_id=${service_id}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  });
}

export async function getOneDrivePermissions(accessToken) {
  return fetch("https://graph.microsoft.com/v1.0/me/drive", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

// const isFileInOneDrive = () => {
//   const fileUrl = Office.context.document.url;
//   console.log("fileUrl", fileUrl);
//   return fileUrl && fileUrl.includes("https://d.docs.live.net");
// };

export const uploadFileToOneDrive = async (accessToken, fileContent, fileName, fileId = null) => {
  const endpoint = fileId
    ? `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}/content`
    : `https://graph.microsoft.com/v1.0/me/drive/root:/${fileName}:/content`;
  const validToken = await ensureValidAccessToken();
  try {
    const response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${validToken}`,
        "Content-Type": "application/octet-stream",
      },
      body: fileContent,
    });

    if (!response.ok) {
      const errorData = await response.json();
      let errorMessage;
      switch (response.status) {
        case 423: // Locked
          errorMessage = "The file is currently open in OneDrive. Please close it and try again.";
          break;
        case 403: // Forbidden
          errorMessage = "You do not have permission to modify this file in OneDrive.";
          break;
        default:
          errorMessage = errorData?.error?.message || "Failed to upload file to OneDrive.";
      }

      throw new Error(errorMessage);
    }
    return response;
  } catch (error) {
    console.error("Error in uploadFileToOneDrive:", error);
    throw error;
  }
};

export async function renameFileOnOneDrive(accessToken, fileId, newFileName) {
  const validToken = await ensureValidAccessToken();
  const response = await fetch(`https://graph.microsoft.com/v1.0/me/drive/items/${fileId}`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${validToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name: newFileName }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Failed to rename file: ${errorData.error.message}`);
  }

  return response;
}

export const getOneDriveFileMetadata = async (accessToken, fileId) => {
  try {
    const validToken = await ensureValidAccessToken();
    const response = await fetch(`https://graph.microsoft.com/v1.0/me/drive/items/${fileId}`, {
      headers: { Authorization: `Bearer ${validToken}` },
    });
    if (response.ok) {
      return await response.json();
    } else {
      console.error("Failed to get file metadata from OneDrive.");
      return null;
    }
  } catch (error) {
    console.error("Error in getOneDriveFileMetadata:", error);
    return null;
  }
};

export const downloadFileFromOneDrive = async (accessToken, fileId) => {
  try {
    const validToken = await ensureValidAccessToken();
    const response = await fetch(`https://graph.microsoft.com/v1.0/me/drive/items/${fileId}/content`, {
      headers: { Authorization: `Bearer ${validToken}` },
    });
    if (response.ok) {
      return await response.arrayBuffer();
    } else {
      console.error("Failed to download file from OneDrive.");
      return null;
    }
  } catch (error) {
    console.error("Error in downloadFileFromOneDrive:", error);
    return null;
  }
};
