import React from "react";
import DynamicForm from "./DynamicFormAccordion";

const reportConfigurations = {
  post_search: {
    queryType: "post_search",
    hashtags: "",
    post_search_type: "recent_media",
    num_posts_by_hashtag: "50",
  },
  profile_info: {
    queryType: "profile_info",
    usernames: "",
  },
  profile_posts: {
    queryType: "profile_posts",
    usernames: "",
  },
  interest_over_time: {
    queryType: "interest_over_time",
    search_term: "",
    region: "worldwide",
    gprop: "web",
    category: "0",
  },
  interest_by_region: {
    queryType: "profile_interest_by_regionposts",
    search_term: "",
    region: "worldwide",
    gprop: "web",
    category: 0,
  },
  related_queries: {
    queryType: "related_queries",
    search_term: "",
    region: "worldwide",
    gprop: "web",
    category: 0,
    type: "top",
  },
  related_topics: {
    queryType: "related_topics",
    search_term: "",
    region: "worldwide",
    gprop: "web",
    category: 0,
    type: "top",
  },
  related_suggestions: {
    queryType: "related_suggestions",
    search_term: "",
  },
  daily_search_trends: {
    queryType: "daily_search_trends",
    region: "worldwide",
  },
  top_charts: {
    queryType: "top_charts",
    region: "worldwide",
    year: "2004",
  },
  page: {
    queryType: "page",
    page_ids: "",
  },
  page_posts: {
    queryType: "page_posts",
    page_ids: "",
    number_of_posts: "250",
  },
  historical: {
    queryType: "historical",
    keywords: "",
    country: "",
    language: "",
    keyword_plan_network: 0,
  },
  ideas: {
    queryType: "ideas",
    keywords: "",
    page_url: "",
    country: "",
    language: "",
    keyword_plan_network: 0,
    include_adult_keywords: false,
  },
};
const deepEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);
const ReportConfigAccordion = ({
  sections,
  reportOptionSelected,
  setSelectedReportOption,
  reportOptions,
  setDynamicFormValues,
  dynamicFormValues,
  setSelectedAccounts,
}) => {
  const [selectedQueryType, setSelectedQueryType] = React.useState(sections[0].values[0].id);
  const [formValues, setFormValues] = React.useState(dynamicFormValues || {});

  const handleQueryTypeChange = React.useCallback(
    (newValues) => {
      const selectedType = newValues.queryType || selectedQueryType;
      setSelectedQueryType(selectedType);
      setSelectedReportOption(selectedType);

      const currentConfig = reportConfigurations[selectedType] || {};

      const filteredValues = Object.keys(currentConfig).reduce((acc, key) => {
        acc[key] = newValues[key] !== undefined ? newValues[key] : currentConfig[key];
        return acc;
      }, {});

      if (!deepEqual(filteredValues, formValues)) {
        setFormValues(filteredValues);
      }

      if (filteredValues.hashtags || filteredValues.usernames || filteredValues.page_ids) {
        const accountsArray = [];
        if (filteredValues.hashtags) {
          accountsArray.push({ hashtags: filteredValues.hashtags });
        }
        if (filteredValues.usernames) {
          accountsArray.push({ usernames: filteredValues.usernames });
        }
        if (filteredValues.page_ids) {
          accountsArray.push({ page_ids: filteredValues.page_ids });
        }
        setSelectedAccounts(accountsArray);
      }
    },
    [selectedQueryType, setSelectedReportOption, setSelectedAccounts, formValues]
  );

  React.useEffect(() => {
    if (sections && sections.length > 0 && Object.keys(formValues).length === 0) {
      const newConfig = reportConfigurations[sections[0].values[0].id] || {};
      if (!deepEqual(newConfig, formValues)) {
        setSelectedQueryType(sections[0].values[0].id);
        setFormValues(newConfig);
      }
    }
  }, [sections, formValues]);

  React.useEffect(() => {
    if (Object.keys(formValues).length > 0 && !deepEqual(formValues, dynamicFormValues)) {
      setDynamicFormValues(formValues);
    }
  }, [formValues, setDynamicFormValues, dynamicFormValues]);

  return (
    <>
      <DynamicForm
        sections={sections}
        options={reportOptions}
        onValuesChange={handleQueryTypeChange}
        dynamicFormValues={formValues}
      />
    </>
  );
};

export default ReportConfigAccordion;
