import { config } from "../Constants";
import { deleteSchedule, getTemplates, saveSchedule } from "../services/actions";
import { addIssue, getAccountsByTaskId, getAllUserAccounts } from "../services/connector";
import { registerUser } from "../services/login";
import { connectorsWithoutAccounts } from "./connectorsConfiguration";
import { cleanDate, formatDateTime, isValidDate } from "./formatDates";
import { generateUserCode, getFileWebUrlById, syncWithOneDrive } from "./office-apis-helpers";

//Utilities

let dialog = null;

export function showDialog(title, error) {
  const dialogData = {
    title: title,
    errors: error,
  };

  localStorage.setItem("dialogData", JSON.stringify(dialogData));

  const dialogOptions = {
    height: 60,
    width: 50,
    displayInIframe: true,
  };

  Office.context.ui.displayDialogAsync(
    `${process.env.REACT_APP_BASE_URL}/popup/popup.html`,
    dialogOptions,
    function (asyncResult) {
      if (asyncResult.status === Office.AsyncResultStatus.Failed) {
      } else {
        dialog = asyncResult.value;
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, function (args) {
          if (args.message === "requestData") {
            const storedData = localStorage.getItem("dialogData");
            dialog.messageChild(storedData);
          } else if (args.message === "close") {
            closeDialog();
          }
        });
        dialog.addEventHandler(Office.EventType.DialogEventReceived, function (args) {
          dialog = null;
        });
      }
    }
  );
}

export function closeDialog() {
  try {
    if (dialog) {
      dialog.close();
    }
  } catch (error) {
    console.error("Error al cerrar el diálogo:", error);
  } finally {
    localStorage.removeItem("dialogData");
    dialog = null;
  }
}

function transformQueryDataForAPI(queryData) {
  const isBlendConnector = queryData.dataSource.startsWith("blend");
  return {
    // ...queryData,
    queryUUID: queryData.queryUUID,
    cache: true,
    dates: {
      end_date: cleanDate(queryData.endDate),
      start_date: cleanDate(queryData.startDate),
      date_range_type: queryData.dateRangeType,
    },
    timezone: getUserTimeZone(),
    accounts: JSON.parse(queryData.accountsViews),
    call_type: queryData.reportType,
    page_ids: queryData.accountsViews
      ? Array.isArray(JSON.parse(queryData.accountsViews)) &&
        JSON.parse(queryData.accountsViews)[0]?.hasOwnProperty("page_ids")
        ? JSON.parse(queryData.accountsViews)[0].page_ids
        : ""
      : "",
    metrics: isBlendConnector
      ? JSON.parse(queryData.metrics)
      : JSON.parse(queryData.metrics).map((metric) => metric.id),
    filters: JSON.parse(queryData.filters),
    refreshWithUserAccount: JSON.parse(queryData.refreshWithUserAccount),
    sort_by: JSON.parse(queryData.sort),
    sort_dims_by: JSON.parse(queryData.sortDimensions),
    options: {},
    high_priority: true,
    compare_dates: {},
    service_id: config.SERVICE_ID,
    connections: JSON.parse(queryData.refreshWithUserAccount),
    dimensions: {
      rows: isBlendConnector ? JSON.parse(queryData.dimensions) : JSON.parse(queryData.dimensions).map((dim) => dim.id),

      cols: isBlendConnector
        ? JSON.parse(queryData.pivotDimensions)
        : JSON.parse(queryData.pivotDimensions).map((dim) => dim.id),

      limit_rows: queryData.maxRows || 0,
      limit_cols: queryData.maxPivotCategories || 0,
    },
    sheet_name: JSON.parse(queryData.otherParameters).sheet_name || "",
    sheet_range: JSON.parse(queryData.otherParameters).sheet_range || "",
    document_url: JSON.parse(queryData.otherParameters).document_url || "",
    number_of_posts: queryData.otherParameters?.number_of_posts ?? "",
    num_posts_by_hashtag: queryData.otherParameters?.num_posts_by_hashtag ?? 0,
    post_search_type: queryData.otherParameters?.post_search_type ?? "",
    hashtags: queryData.accountsViews
      ? Array.isArray(JSON.parse(queryData.accountsViews)) &&
        JSON.parse(queryData.accountsViews)[0]?.hasOwnProperty("hashtags")
        ? JSON.parse(queryData.accountsViews)[0].hashtags
        : ""
      : "",
    search_term: queryData.otherParameters?.search_term ?? "",
    result_type_related: "",
    region: queryData.otherParameters?.region ?? "",
    gprop: queryData.otherParameters?.gprop ?? "",
    type: queryData.otherParameters?.type ?? "",
    category: queryData.otherParameters?.category ?? "",
    year: queryData.otherParameters?.year ?? "",
    segments: [],
    segment_dim: "",
    search_types: queryData.otherParameters?.search_types ?? [],
    country: queryData.otherParameters?.country ?? "",
    url: queryData.otherParameters?.url ?? "",
    url_type: queryData.otherParameters?.url_type ?? "",
    json_path: queryData.otherParameters?.json_path ?? "",
    headers: queryData.otherParameters?.headers ?? "",
    request: queryData.otherParameters?.request ?? "",
    query_type: queryData.otherParameters?.query_type ?? "",
    usernames: queryData.accountsViews
      ? Array.isArray(JSON.parse(queryData.accountsViews)) &&
        JSON.parse(queryData.accountsViews)[0]?.hasOwnProperty("usernames")
        ? JSON.parse(queryData.accountsViews)[0].usernames
        : ""
      : "",
  };
}

export const handleTemplateGallery = async () => {
  try {
    const response = await getTemplates();
    const templates = await response.json();
    localStorage.setItem("templatesData", JSON.stringify(templates.result));

    Office.context.ui.displayDialogAsync(
      `${process.env.REACT_APP_BASE_URL}/popup/templateGallery.html`,
      { height: 70, width: 70, displayInIframe: true },
      function (asyncResult) {
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
          console.error("Error al abrir el diálogo");
        } else {
          const dialog = asyncResult.value;
          dialog.addEventHandler(Office.EventType.DialogMessageReceived, function (args) {
            if (args.message === "requestData") {
              const storedData = localStorage.getItem("templatesData");
              dialog.messageChild(storedData);
            } else if (args.message === "close") {
              dialog.close();
            }
          });
        }
      }
    );
  } catch (error) {
    console.error("Error fetching templates:", error);
  }
};

export const handleDuplicateFile = async () => {
  const datasources = await getDatasourcesWithQueries();
  if (datasources.length === 0) {
    return;
  }
  Office.context.ui.displayDialogAsync(
    `${process.env.REACT_APP_BASE_URL}/popup/duplicateFile.html`,
    { height: 75, width: 60, displayInIframe: true },
    (asyncResult) => {
      const dialog = asyncResult.value;

      dialog.messageChild(JSON.stringify({ status: "loading" }));
      requestAccountsToDuplicateDocument(datasources)
        .then((accountsResponse) => {
          if (accountsResponse.error) {
            dialog.messageChild(JSON.stringify({ error: accountsResponse.error }));
          } else {
            dialog.messageChild(
              JSON.stringify({
                apiURL: config.API_URL,
                userToken: localStorage.getItem("DS-EXCEL-TOKEN"),
                datasources,
                accounts: accountsResponse.result,
                datasourcesWithoutAccounts: connectorsWithoutAccounts,
              })
            );
          }
        })
        .catch((error) => {
          dialog.messageChild(JSON.stringify({ error: "Error fetching accounts: " + error.message }));
        });

      dialog.addEventHandler(Office.EventType.DialogMessageReceived, async (args) => {
        const messageFromPopup = JSON.parse(args.message);

        if (messageFromPopup.action === "submit") {
          await mainWorkflow(
            messageFromPopup.data.connections,
            messageFromPopup.data.accounts,
            messageFromPopup.data.resetSheetContents
          );
          dialog.close();
        } else if (messageFromPopup.action === "close") {
          dialog.close();
        }
      });
    }
  );
};

export const handleGettingStarted = () => {
  Office.context.ui.displayDialogAsync(
    `${process.env.REACT_APP_BASE_URL}/popup/gettingStarted.html`,
    { height: 80, width: 60, displayInIframe: true },
    function (asyncResult) {
      const dialog = asyncResult.value;

      dialog.addEventHandler(Office.EventType.DialogMessageReceived, async (args) => {
        const messageFromPopup = JSON.parse(args.message);

        if (messageFromPopup.action === "submit") {
          // accion
        } else if (messageFromPopup === "close") {
          dialog.close();
        }
      });
    }
  );
};

export const handleReportQuery = (token, query) => {
  Office.context.ui.displayDialogAsync(
    `${process.env.REACT_APP_BASE_URL}/popup/reportPopup.html`,
    { height: 60, width: 40, displayInIframe: true },
    function (asyncResult) {
      const dialog = asyncResult.value;

      dialog.addEventHandler(Office.EventType.DialogMessageReceived, async (args) => {
        const messageFromPopup = JSON.parse(args.message);
        if (messageFromPopup.action === "submit") {
          const queryDataToReport = transformQueryDataForAPI(query);
          const isBlendConnector = query.dataSource.startsWith("blend");
          const blendName = isBlendConnector ? query.dataSource : "";
          const datasource = isBlendConnector ? "" : query.dataSource;
          try {
            await addIssue(
              token,
              queryDataToReport,
              datasource,
              blendName,
              messageFromPopup.title,
              messageFromPopup.description,
              config.SERVICE_ID
            );

            dialog.close();

            setTimeout(() => {
              showDialog("Report Completed", ["Your issue has been reported successfully."]);
            }, 500);
          } catch (error) {
            console.error("Error sending issue:", error);
            dialog.close();

            setTimeout(() => {
              showDialog("Error", ["There was an error sending the issue. Please try again."]);
            }, 500);
          }
        } else if (messageFromPopup === "close") {
          dialog.close();
        }
      });
    }
  );
};

export const handleUserRegister = (email, countries, setAuthStatus, setErrorMessage, login) => {
  const dialogOptions = {
    height: 80,
    width: 40,
    displayInIframe: true,
  };

  if (!Office.context.ui) {
    console.error("Office.context.ui no está disponible");
    return;
  }

  Office.context.ui.displayDialogAsync(
    `${process.env.REACT_APP_BASE_URL}/popup/register.html`,
    dialogOptions,
    (asyncResult) => {
      if (asyncResult.status === Office.AsyncResultStatus.Failed) {
        console.error("Error al abrir el diálogo:", asyncResult.error.message);
      } else {
        const dialog = asyncResult.value;

        // Evento cuando el usuario cierra manualmente el diálogo
        dialog.addEventHandler(Office.EventType.DialogEventReceived, () => {
          setAuthStatus("notLoggedIn");
          localStorage.clear();
        });

        dialog.addEventHandler(Office.EventType.DialogMessageReceived, (args) => {
          try {
            const messageFromPopup = JSON.parse(args.message);

            if (messageFromPopup.action === "requestData") {
              // Enviar datos iniciales al diálogo
              dialog.messageChild(
                JSON.stringify({
                  action: "setEmailAndCountries",
                  email,
                  countries,
                })
              );
            } else if (messageFromPopup.action === "registerData") {
              setAuthStatus("registerInProcess");
              dialog.close();

              // Procesar los datos de registro recibidos
              const formData = messageFromPopup.data;
              registerUser(formData, config.SERVICE_ID)
                .then((response) => response.json())
                .then((data) => {
                  if (data.success) {
                    setErrorMessage("");
                    if (login) login();
                  }
                })
                .catch(() => {
                  setAuthStatus("notLoggedIn");
                  setTimeout(() => {
                    showDialog("Error", ["Error registering the user. Please try again."]);
                  }, 300);
                });
            } else if (messageFromPopup.action === "close") {
              dialog.close();
            }
          } catch (error) {
            console.error("Error procesando mensaje del diálogo:", error);
            dialog.close();
          }
        });
      }
    }
  );
};

export const handleSchedule = async (updateMessage, schedule = null, onScheduleUpdated = null) => {
  try {
    const syncResult = await syncWithOneDrive(updateMessage);
    if (!syncResult.success) {
      return;
    }

    const userTimeZone = schedule?.timezone || getUserTimeZone();
    const userEmail = localStorage.getItem("DS-EXCEL-USERNAME");

    const documentUrl = syncResult.url;
    const documentName = syncResult.name;
    const fileId = syncResult.fileId;
    const urlweb = await getFileWebUrlById(localStorage.getItem("DS-EXCEL-TOKEN"), fileId);

    await Excel.run(async (context) => {
      const workbook = context.workbook;
      const worksheets = workbook.worksheets;
      worksheets.load("items/name");
      await context.sync();

      const sheetNames = worksheets.items
        .map((sheet) => sheet.name)
        .filter((name) => name !== "DataslayerQueries" && name !== "OneDriveInfo");

      if (!Office.context.ui) {
        console.error("Office.context.ui is not available");
        return;
      }

      Office.context.ui.displayDialogAsync(
        `${process.env.REACT_APP_BASE_URL}/schedule/schedule.html`,
        { height: 70, width: 70, displayInIframe: true },
        function (asyncResult) {
          if (asyncResult.status === Office.AsyncResultStatus.Failed) {
            console.error("Failed to open dialog:", asyncResult.error.message);
          } else {
            const dialog = asyncResult.value;

            const dataToSend = schedule
              ? {
                  action: "editSchedule",
                  urlweb: urlweb,
                  scheduleData: {
                    scheduleId: schedule.schedule_id,
                    type: schedule.type,
                    timezone: schedule.timezone,
                    allSheets: sheetNames,
                    sheets: schedule.json_params.sheets || [],
                    send_email: schedule.json_params.send_email || false,
                    send_fail_email: schedule.json_params.send_fail_email || false,
                    send_pdf_email: schedule.json_params.send_pdf_email || false,
                    email_address: schedule.json_params.email_address || "",
                    day: schedule.json_params.day || "",
                    time: schedule.json_params.time || "",
                  },
                }
              : {
                  action: "createSchedule",
                  urlweb: urlweb,
                  sheets: sheetNames,
                  timezone: userTimeZone,
                  documentUrl: documentUrl,
                  documentName: documentName,
                  userEmail: userEmail,
                };

            dialog.addEventHandler(Office.EventType.DialogMessageReceived, function (args) {
              if (args.message === "requestData") {
                dialog.messageChild(JSON.stringify(dataToSend));
              } else if (args.message === "close") {
                dialog.close();
              }
            });

            dialog.addEventHandler(Office.EventType.DialogMessageReceived, async function (args) {
              try {
                const receivedData = JSON.parse(args.message);
                if (receivedData.action === "saveTrigger") {
                  const dataToSend = {
                    // document_url: `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}/content`,
                    document_url: urlweb,
                    document_name: receivedData.formData.documentName || documentName,
                    timezone: receivedData.formData.timezone || userTimeZone,
                    json_params: receivedData.formData.json_params,
                    type: receivedData.formData.type,
                    metadata_data: {
                      refresh_token: localStorage.getItem("DS-EXCEL-REFRESH-TOKEN"),
                      document_url: `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}/content`,
                    },
                  };
                  const response = await saveSchedule(config.SERVICE_ID, userEmail, dataToSend);

                  if (response.ok) {
                    const registeredSchedule = await response.json();

                    dialog.messageChild(
                      JSON.stringify({
                        action: "saveSuccess",
                        message: "The schedule was saved successfully",
                        scheduleId: registeredSchedule.schedule_id,
                        timezone: userTimeZone,
                        documentUrl: documentUrl,
                        documentName: documentName,
                      })
                    );
                    if (onScheduleUpdated) {
                      onScheduleUpdated();
                    }
                    dialog.close();
                  } else {
                    const errorText = await response.text();
                    console.error("Error al guardar el schedule:", errorText);
                  }
                }
                if (receivedData.action === "deleteTrigger") {
                  const response = await deleteSchedule(config.SERVICE_ID, userEmail, receivedData.scheduleId);

                  if (response.ok) {
                    dialog.messageChild(
                      JSON.stringify({
                        action: "deleteSuccess",
                        message: "All scheduled updates have been successfully removed...",
                        timezone: userTimeZone,
                        documentUrl: documentUrl,
                        documentName: documentName,
                        scheduleId: null,
                      })
                    );
                    if (onScheduleUpdated) {
                      onScheduleUpdated();
                    }
                    if (receivedData.close) {
                      dialog.close();
                    }
                  } else {
                    const errorText = await response.text();
                    console.error("Error al eliminar el schedule:", errorText);
                  }
                }
              } catch (error) {
                console.error("Error al recibir o procesar los datos:", error);
              }
            });
          }
        }
      );
    });
  } catch (error) {
    console.error("Error fetching sheet names:", error);
  }
};

export function generateUUID() {
  return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function getUserTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

// Cargar y sincronizar la hoja de cálculo
async function loadWorksheet(context, sheetName) {
  const sheet = context.workbook.worksheets.getItem(sheetName);
  const range = sheet.getUsedRange();
  range.load("values");
  await context.sync();
  return { sheet, range, values: range.values };
}

// Buscar una fila por queryId
function findQueryRow(values, queryId) {
  for (let i = 0; i < values.length; i++) {
    if (values[i][0] === queryId) {
      return i;
    }
  }
  return -1;
}

// Obtener la fecha formateada en la zona horaria del usuario
function getFormattedCurrentDate() {
  const timeZone = getUserTimeZone();
  return formatDateTime(new Date(), timeZone, "yyyy-MM-dd HH:mm:ss");
}

// Encontrar el rango para actualizar una query existente
async function findRangeForUpdate(context, queryUUID) {
  const { sheet, values } = await loadWorksheet(context, "DataslayerQueries");
  const rowIndex = findQueryRow(values, queryUUID);

  if (rowIndex === -1) {
    console.error("Query ID no encontrado en la hoja DataslayerQueries");
    return null;
  }

  // Usamos getQueryInfo para obtener sheetName y tableRange
  const { sheetName, tableRange, createdDate } = getQueryInfo(values, rowIndex);
  const startCell = tableRange.split(":")[0];
  const targetSheet = context.workbook.worksheets.getItem(sheetName);
  const selectedRange = targetSheet.getRange(startCell);
  return { selectedRange, sheetName, createdDate };
}

// Obtener el rango seleccionado o el rango de inicio predeterminado
function getSelectedOrStartRange(context, sheet, startCell) {
  let selectedRange = sheet.getRange(startCell);

  if (context.workbook.getSelectedRange) {
    selectedRange = context.workbook.getSelectedRange();
  }

  return selectedRange;
}

function buildQueryData(params) {
  const {
    queryUUID,
    sheetName,
    rangeAddress,
    currentDate,
    queryRequest,
    connector,
    executionTime,
    isUpdate,
    createdDate,
  } = params;
  const stringifyValue = (value) => {
    try {
      return typeof value === "object" ? JSON.stringify(value) : value;
    } catch (e) {
      console.error("Error al convertir valor a string:", e);
      return value;
    }
  };

  return {
    queryUUID: stringifyValue(queryUUID),
    sheetName: stringifyValue(sheetName),
    rangeAddress: stringifyValue(rangeAddress),
    created: isUpdate
      ? createdDate
      : formatDateTime(
          new Date(cleanDate(queryRequest.created) || cleanDate(currentDate)),
          getUserTimeZone(),
          "yyyy-MM-dd HH:mm:ss"
        ),
    updated: isUpdate ? currentDate : "",
    lastStatus: queryRequest.created ? "Refreshed successfully" : "Created successfully",
    lastRefreshID: "",
    resultsContainSampledData: "",
    executionTime: stringifyValue(parseInt(executionTime)),
    objectType: "TABLE",
    linkedChartID: "",
    dataSource: connector,
    dateRangeType: stringifyValue(queryRequest.dateRangeType || ""),
    startDate: isValidDate(queryRequest.startDate)
      ? formatDateTime(new Date(queryRequest.startDate), getUserTimeZone(), "yyyy-MM-dd")
      : queryRequest.startDate,
    // queryRequest.startDate && formatDateTime(new Date(queryRequest.startDate), getUserTimeZone(), "yyyy-MM-dd"),
    endDate: isValidDate(queryRequest.endDate)
      ? formatDateTime(new Date(queryRequest.endDate), getUserTimeZone(), "yyyy-MM-dd")
      : queryRequest.endDate,
    // queryRequest.endDate && formatDateTime(new Date(queryRequest.endDate), getUserTimeZone(), "yyyy-MM-dd"),
    compareTo: stringifyValue(queryRequest.compareTo) || "",
    comparisonValueType: stringifyValue(queryRequest.comparisonValueType || ""),
    accountsViews: stringifyValue(queryRequest.accountsViews) || "",
    metrics: stringifyValue(queryRequest.metrics) || "",
    dimensions: stringifyValue(queryRequest.dimensions) || "",
    pivotDimensions: stringifyValue(queryRequest.pivotDimensions) || "",
    filters: stringifyValue(queryRequest.filters) || "",
    segmentID: "",
    segmentDim: "",
    sort: stringifyValue(queryRequest.sort_by) || "",
    maxRows: stringifyValue(queryRequest.maxRows || 0),
    maxPivotCategories: stringifyValue(queryRequest.maxPivotCategories || 0),
    specialSettings: stringifyValue(queryRequest.options) || "",
    otherParameters: stringifyValue(queryRequest.otherParameters) || "",
    resultType: "",
    languageCountry: "",
    translateTo: "",
    sql: "",
    databaseName: "",
    rangeAddressStatic: "",
    reportType: stringifyValue(queryRequest.reportType || ""),
    highlightWithColour: "",
    refreshWithUserAccount: stringifyValue(queryRequest.refreshWithUserAccount) || "",
    returnedData: "",
    sortDimensions: stringifyValue(queryRequest.sort_dims_by) || "",
    createdByUserAccount: stringifyValue(localStorage.getItem("DS-EXCEL-USERNAME")),
    blendName: stringifyValue(queryRequest.blendName || ""),
  };
}

// Obtener o crear una hoja
async function getOrCreateSheet(context, sheetName) {
  let sheet;
  try {
    sheet = context.workbook.worksheets.getItem(sheetName);
  } catch (error) {
    sheet = context.workbook.worksheets.add(sheetName);
  }
  sheet.load("name");
  await context.sync();
  return sheet;
}

// Obtener la última fila utilizada en la hoja
async function getLastRow(context, sheet) {
  const usedRange = sheet.getUsedRange();
  usedRange.load(["rowIndex", "rowCount"]);
  await context.sync();
  return usedRange.rowIndex + usedRange.rowCount;
}

// Función para obtener información de la query (nombre de la hoja y rango)
function getQueryInfo(values, rowIndex) {
  return {
    sheetName: values[rowIndex][1],
    tableRange: values[rowIndex][2],
    createdDate: values[rowIndex][3],
  };
}

// Función para eliminar la tabla de la hoja de destino
async function deleteTableFromSheet(context, sheetName, tableRange) {
  try {
    const targetSheet = context.workbook.worksheets.getItem(sheetName);
    const tableRangeToDelete = targetSheet.getRange(tableRange);
    tableRangeToDelete.clear();
    await context.sync();
  } catch (error) {
    console.error(`No se pudo eliminar la tabla en la hoja ${sheetName} con rango ${tableRange}:`, error);
  }
}

// Función para eliminar la fila de la hoja DataSlayerQueries
async function deleteRowFromSheet(sheet, rowIndex) {
  if (rowIndex !== -1) {
    sheet.getRange(`${rowIndex + 1}:${rowIndex + 1}`).delete(Excel.DeleteShiftDirection.up);
  }
}

// Construir una nueva fila de datos para registrar una query
function buildQueryRow(queryData) {
  return [
    queryData.queryUUID || "",
    queryData.sheetName || "",
    queryData.rangeAddress || "",
    queryData.created || "",
    queryData.updated || "",
    queryData.lastStatus || "",
    queryData.lastRefreshID || "",
    queryData.resultsContainSampledData || "",
    queryData.executionTime || "",
    queryData.objectType || "",
    queryData.linkedChartID || "",
    queryData.dataSource || "",
    queryData.dateRangeType || "",
    queryData.startDate || "",
    queryData.endDate || "",
    queryData.compareTo || "",
    queryData.comparisonValueType || "",
    queryData.accountsViews || "",
    queryData.metrics || "",
    queryData.dimensions || "",
    queryData.pivotDimensions || "",
    queryData.filters || "",
    queryData.segmentID || "",
    queryData.segmentDim || "",
    queryData.sort || "",
    queryData.maxRows || "",
    queryData.maxPivotCategories || "",
    queryData.specialSettings || "",
    queryData.otherParameters || "",
    queryData.resultType || "",
    queryData.languageCountry || "",
    queryData.translateTo || "",
    queryData.sql || "",
    queryData.databaseName || "",
    queryData.rangeAddressStatic || "",
    queryData.reportType || "",
    queryData.highlightWithColour || "",
    queryData.refreshWithUserAccount || "",
    queryData.returnedData || "",
    queryData.sortDimensions || "",
    queryData.createdByUserAccount || "",
    queryData.blendName || "",
  ];
}

function convertToAbsoluteRange(rangeAddress) {
  if (rangeAddress.includes("!")) {
    rangeAddress = rangeAddress.split("!")[1];
  }
  rangeAddress = rangeAddress.replace(/([A-Z]+)(\d+)/g, "$$$1$$$2");
  return rangeAddress;
}

//Event to subscribe to catch cells and queries
export async function subscribeToSelectionChange(onCellSelectionChanged) {
  try {
    await Excel.run(async (context) => {
      const sheets = context.workbook.worksheets;
      sheets.load("items");
      await context.sync();

      sheets.items.forEach((sheet) => {
        sheet.onSelectionChanged.add(onCellSelectionChanged);
      });

      await context.sync();
    });
  } catch (error) {
    console.error("Error al suscribirse al evento de selección de celda:", error);
  }
}

export async function unsubscribeFromSelectionChange(onCellSelectionChanged) {
  try {
    await Excel.run(async (context) => {
      const sheets = context.workbook.worksheets;
      sheets.load("items");
      await context.sync();

      sheets.items.forEach((sheet) => {
        sheet.onSelectionChanged.remove(onCellSelectionChanged);
      });

      await context.sync();
    });
  } catch (error) {
    console.error("Error al eliminar la suscripción al evento de selección de celda:", error);
  }
}

export async function onCellSelectionChanged(eventArgs, queries, selectQueryInList) {
  try {
    await Excel.run(async (context) => {
      const sheet = context.workbook.worksheets.getItem(eventArgs.worksheetId);
      const range = sheet.getRange(eventArgs.address);

      range.load("address, rowIndex");
      sheet.load("name");
      await context.sync();

      const selectedSheetName = sheet.name;
      if (selectedSheetName === "DataslayerQueries") {
        const queryUuidRange = sheet.getRange(`A${range.rowIndex + 1}`);
        queryUuidRange.load("values");
        await context.sync();

        const queryUUID = queryUuidRange.values[0][0];

        identifyQueryByUuid(queries, queryUUID, selectQueryInList);
      } else {
        const selectedAddress = range.address;
        identifyQueryByCellAddress(queries, selectedAddress, selectedSheetName, selectQueryInList);
      }
    });
  } catch (error) {
    console.error("Error en onCellSelectionChanged:", error);
  }
}

export function identifyQueryByUuid(queries, queryUUID, selectQueryInList) {
  let foundQuery = false;

  for (let query of queries) {
    if (query.queryUUID === queryUUID) {
      selectQueryInList(queryUUID);
      foundQuery = true;
      break;
    }
  }

  if (!foundQuery) {
    selectQueryInList(null);
  }
}

function parseRangeAddress(rangeAddress) {
  const match = rangeAddress.match(/([$]?)([A-Z]+)([$]?)(\d+)(?::([$]?)([A-Z]+)([$]?)(\d+))?/);

  if (!match) {
    throw new Error(`Rango no válido: ${rangeAddress}`);
  }

  const [, , startCol, , startRow, , endCol, , endRow] = match;
  return {
    startColumn: startCol,
    startRow: parseInt(startRow, 10),
    endColumn: endCol || startCol,
    endRow: parseInt(endRow || startRow, 10),
  };
}

function isCellInRange(cellAddress, queryRange) {
  const cell = parseRangeAddress(cellAddress);
  const range = parseRangeAddress(queryRange);

  const startColumn = columnToNumber(cell.startColumn);
  const endColumn = columnToNumber(range.endColumn);

  const isInRowRange = cell.startRow >= range.startRow && cell.startRow <= range.endRow;
  const isInColumnRange = startColumn >= columnToNumber(range.startColumn) && startColumn <= endColumn;

  return isInRowRange && isInColumnRange;
}

function columnToNumber(column) {
  let columnNumber = 0;
  const columnString = column.toUpperCase();

  for (let i = 0; i < columnString.length; i++) {
    columnNumber = columnNumber * 26 + (columnString.charCodeAt(i) - "A".charCodeAt(0) + 1);
  }

  return columnNumber;
}

export function identifyQueryByCellAddress(queries, selectedAddress, selectedSheetName, selectQueryInList) {
  const convertedAddress = convertToAbsoluteRange(selectedAddress);
  let foundQuery = false;

  for (let query of queries) {
    if (selectedSheetName === query.sheetName) {
      if (isCellInRange(convertedAddress, query.rangeAddress)) {
        selectQueryInList(query.queryUUID);
        foundQuery = true;
        break;
      }
    }
  }

  if (!foundQuery) {
    selectQueryInList(null);
  }
}

export async function updateQuerySheet(queryId, executionTime, refreshStatusQuery, data) {
  try {
    return await Excel.run(async (context) => {
      const { sheet: configSheet, values } = await loadWorksheet(context, "DataslayerQueries");
      const rowIndex = findQueryRow(values, queryId.queryUUID);
      if (rowIndex === -1) {
        console.error("Query ID no encontrado en la hoja DataslayerQueries");
        return;
      }

      const updatedDate = getFormattedCurrentDate();

      configSheet.getRangeByIndexes(rowIndex, 4, 1, 1).values = [[updatedDate]];
      configSheet.getRangeByIndexes(rowIndex, 5, 1, 1).values = [["Refreshed successfully"]];
      configSheet.getRangeByIndexes(rowIndex, 8, 1, 1).values = [[executionTime]];

      const startRange = values[rowIndex][2];
      const targetSheetName = values[rowIndex][1];
      const targetSheet = context.workbook.worksheets.getItem(targetSheetName);
      const startCell = startRange.split(":")[0];
      const selectedRange = targetSheet.getRange(startCell);

      const dataRange = selectedRange.getResizedRange(data.length - 1, data[0].length - 1);
      dataRange.values = data;

      await context.sync();

      const updatedQuery = {
        queryUUID: queryId,
        lastUpdate: updatedDate,
        lastStatus: "Refreshed successfully",
        executionTime: executionTime,
      };

      refreshStatusQuery(updatedQuery);
    });
  } catch (error) {
    console.error("Error al actualizar la consulta:", error);
    throw error;
  }
}

export async function createHiddenSheet(context) {
  const workbook = context.workbook;
  let sheet;
  try {
    sheet = workbook.worksheets.getItem("DataslayerQueries");
    await sheet.load("name");
    await context.sync();
  } catch (error) {
    sheet = workbook.worksheets.add("DataslayerQueries");
  }
  sheet.visibility = Excel.SheetVisibility.hidden;
  const headers = [
    "Query UUID",
    "Sheet name",
    "Range address",
    "Created",
    "Updated",
    "Last status",
    "Last refresh ID",
    "Results contain sampled data",
    "Execution time (sec)",
    "Object type",
    "Linked chart ID",
    "Data source",
    "Date range type",
    "Start date",
    "End date",
    "Compare to",
    "Comparison value type",
    "Accounts/views",
    "Metrics",
    "Dimensions",
    "Pivot dimensions",
    "Filters",
    "Segment ID",
    "Segment dim",
    "Sort",
    "Max rows",
    "Max pivot categories",
    "Special settings",
    "Other parameters",
    "Result type",
    "Language/country",
    "Translate to",
    "SQL",
    "Database name",
    "Range address (static)",
    "Report type",
    "Highlight with colour",
    "Refresh with user account",
    "Returned data",
    "Sort dimensions",
    "Created by user account",
    "Blend name",
  ];

  const range = sheet.getRange("A1:AP1");
  range.values = [headers];

  await context.sync();
}

// Función principal para verificar si el rango seleccionado se solapa con los rangos registrados
export async function checkRangeOverlap(registeredRanges) {
  try {
    return await Excel.run(async (context) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      sheet.load("name");

      const selectedRange = context.workbook.getSelectedRange();
      selectedRange.load("address");

      await context.sync();

      const activeSheetName = sheet.name;
      const selectedRangeAddress = selectedRange.address;

      if (!selectedRangeAddress) {
        return false;
      }

      const isOverlappingWithRegistered = registeredRanges.some(({ sheetName, rangeAddress }) => {
        return isOverlapping(activeSheetName, selectedRangeAddress, sheetName, rangeAddress);
      });

      return isOverlappingWithRegistered;
    });
  } catch (error) {
    console.error("Error checking range overlap:", error);
    return false;
  }
}

// Función para verificar si dos rangos se solapan
function isOverlapping(sheetName1, range1, sheetName2, range2) {
  const [sheet1, startCell1, endCell1] = parseRange(sheetName1, range1);
  const [sheet2, startCell2, endCell2] = parseRange(sheetName2, range2);

  if (!sheet1 || !sheet2 || !startCell1 || !startCell2) {
    return false;
  }

  if (sheet1 !== sheet2) {
    return false;
  }

  const [startRow1, startCol1] = getRowCol(startCell1);
  const [endRow1, endCol1] = getRowCol(endCell1);

  const [startRow2, startCol2] = getRowCol(startCell2);
  const [endRow2, endCol2] = getRowCol(endCell2);

  return startRow1 <= endRow2 && endRow1 >= startRow2 && startCol1 <= endCol2 && endCol1 >= startCol2;
}

// Función para analizar el rango
function parseRange(sheetName, range) {
  if (!range || typeof range !== "string") {
    console.error("El rango no es válido o está indefinido:", range);
    return [null, null, null];
  }
  const [startCell, endCell] = range.includes(":") ? range.split(":") : [range, range];

  const cleanStartCell = startCell.replace(/\$/g, "");
  const cleanEndCell = endCell ? endCell.replace(/\$/g, "") : cleanStartCell;

  return [sheetName, cleanStartCell, cleanEndCell];
}

// Función para convertir una celda (e.g., A1) en su fila y columna numérica
function getRowCol(cell) {
  const match = cell.match(/([A-Z]+)(\d+)/);
  const col = match[1].split("").reduce((sum, char) => sum * 26 + (char.charCodeAt(0) - 64), 0);
  const row = parseInt(match[2], 10);
  return [row, col];
}

export function isDataslayerQueriesActive() {
  return Excel.run(async (context) => {
    const activeSheet = context.workbook.worksheets.getActiveWorksheet();
    activeSheet.load("name");
    await context.sync();
    return activeSheet.name === "DataslayerQueries";
  }).catch((error) => {
    console.error("Error checking active sheet:", error);
    return false;
  });
}

export function writeDataToExcel(
  data,
  startCell = "A1",
  queryUUID,
  queryRequest,
  connector,
  executionTime,
  addOrUpdateQuery,
  isUpdate = false,
  openErrorDialog
) {
  return Excel.run(async (context) => {
    let range = null;
    try {
      const accountsViewsString = JSON.stringify(queryRequest.accountsViews || {});
      if (accountsViewsString.length > 32767) {
        throw new Error(
          "The accounts selected exceed the maximum number of characters allowed by Excel for a single cell. It is recommended to select 'all accounts' to avoid issues."
        );
      }

      let selectedRange, sheetName, createdDate;
      const isDSActive = await isDataslayerQueriesActive();

      if (!isUpdate && isDSActive) {
        showDialog("The query cannot be created", [
          "You cannot insert data into the DataslayerQueries sheet. Please change to another sheet and try again",
        ]);
        return;
      }

      if (isUpdate) {
        const result = await findRangeForUpdate(context, queryUUID);
        if (!result) return;

        selectedRange = result.selectedRange;
        sheetName = result.sheetName;
        createdDate = result.createdDate;
        selectedRange.load("address");
        await context.sync();
        const existingRange = selectedRange.getSurroundingRegion();
        existingRange.clear("Contents");
        await context.sync();
      } else {
        const sheet = context.workbook.worksheets.getActiveWorksheet();
        sheet.load("name");
        await context.sync();
        selectedRange = getSelectedOrStartRange(context, sheet, startCell);
        sheetName = sheet.name;
      }

      range = selectedRange.getResizedRange(data.length - 1, data[0].length - 1);
      range.values = data;
      await context.sync();

      // Convertir URLs en hipervínculos y establecer el formato como texto
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      const usedRange = sheet.getUsedRange();
      usedRange.load("values");

      await context.sync();

      usedRange.values.forEach((row, rowIndex) => {
        row.forEach((cellValue, colIndex) => {
          if (typeof cellValue === "string" && cellValue.startsWith("http")) {
            const cell = usedRange.getCell(rowIndex, colIndex);
            cell.hyperlink = {
              address: cellValue,
              textToDisplay: cellValue,
              screenTip: "Click to open the link",
            };
          }
        });
      });

      await context.sync();

      range.load("address");
      await context.sync();
      const rangeAddress = convertToAbsoluteRange(range.address);
      const currentDate = getFormattedCurrentDate();
      const queryData = buildQueryData({
        queryUUID,
        sheetName,
        rangeAddress,
        currentDate,
        queryRequest,
        connector,
        executionTime,
        isUpdate,
        createdDate,
      });
      isUpdate ? await refreshQuery(queryData, addOrUpdateQuery) : await registerQuery(queryData, addOrUpdateQuery);
    } catch (error) {
      if (range) {
        range.clear("Contents");
        await context.sync();
      }
      if (openErrorDialog) {
        openErrorDialog("Error writing data to Excel", [
          "Some values do not comply with Excel's format, so the table cannot be written. We are working to resolve the issue.",
        ]);
      }
    }
  });
}

// Función para registrar una nueva query en la hoja DataslayerQueries
export async function registerQuery(queryData, addQuery) {
  addQuery(queryData);
  try {
    await Excel.run(async (context) => {
      const sheetName = "DataslayerQueries";
      const sheet = await getOrCreateSheet(context, sheetName);

      const lastRow = await getLastRow(context, sheet);
      const newRow = buildQueryRow(queryData);

      const range = sheet.getRange(`A${lastRow + 1}:AP${lastRow + 1}`);

      sheet.getRange(`D${lastRow + 1}:D${lastRow + 1}`).numberFormat = [["@"]];
      sheet.getRange(`E${lastRow + 1}:E${lastRow + 1}`).numberFormat = [["@"]];
      sheet.getRange(`N${lastRow + 1}:N${lastRow + 1}`).numberFormat = [["@"]];
      sheet.getRange(`O${lastRow + 1}:O${lastRow + 1}`).numberFormat = [["@"]];

      range.values = [newRow];

      await context.sync();
    });
  } catch (error) {
    console.error("Error registrando la consulta en Excel:", error);
  }
}

export async function onRemove(queryId, deleteAll) {
  try {
    return await Excel.run(async (context) => {
      // Paso 1: Buscar el nombre de la hoja en DataSlayerQueries
      const { sheet: dataSheet, values } = await loadWorksheet(context, "DataSlayerQueries");
      const rowIndex = findQueryRow(values, queryId);

      if (rowIndex === -1) {
        console.error(`Query ID no encontrado en la hoja DataSlayerQueries`);
        return;
      }

      // Obtener el nombre de la hoja y el rango de la tabla
      const { sheetName, tableRange } = getQueryInfo(values, rowIndex);

      if (!sheetName || !tableRange) {
        console.error(`No se encontró una hoja o rango asociado con queryId: ${queryId}`);
        return;
      }

      if (deleteAll) {
        await deleteTableFromSheet(context, sheetName, tableRange);
      }

      // Paso 3: Eliminar la fila correspondiente en la hoja DataSlayerQueries
      await deleteRowFromSheet(dataSheet, rowIndex);

      await context.sync();
      return queryId;
    });
  } catch (error) {
    console.error("Error al eliminar el query de Excel:", error);
    throw error;
  }
}

export function refreshQuery(query, updatedData) {
  updatedData(query);
  return Excel.run(async (context) => {
    const { sheet, values } = await loadWorksheet(context, "DataslayerQueries");
    const queryRowIndex = findQueryRow(values, query.queryUUID);

    if (queryRowIndex === -1) {
      console.error("Query ID no encontrado en la hoja DataslayerQueries");
      return;
    }

    const newRowValues = buildQueryRow({
      ...query,
      updated: getFormattedCurrentDate(),
      lastStatus: "Refreshed successfully",
    });

    await updateRowInSheet(sheet, queryRowIndex, newRowValues);
  }).catch((error) => {
    console.error("Error al refrescar la query: ", error);
  });
}

// Función para actualizar una fila específica en una hoja de cálculo
async function updateRowInSheet(sheet, rowIndex, newRowValues) {
  const rowRange = sheet.getRange(`A${rowIndex + 1}:AP${rowIndex + 1}`);
  rowRange.values = [newRowValues];
  await sheet.context.sync();
}

async function getOrActivateSheet(context, sheetName) {
  try {
    const sheet = context.workbook.worksheets.getItemOrNullObject(sheetName);
    await context.sync();

    if (sheet.isNullObject) {
      console.error(`La hoja "${sheetName}" no existe en el libro.`);
      return;
    }

    sheet.load("visibility");
    await context.sync();

    if (sheet.visibility === Excel.SheetVisibility.hidden) {
      sheet.visibility = Excel.SheetVisibility.visible;
      await context.sync();
    }
    sheet.activate();
    await context.sync();
    return sheet;
  } catch (error) {
    console.error(`Error al activar la hoja ${sheetName}:`, error);
    throw error;
  }
}

export async function manageQueries() {
  try {
    await Excel.run(async (context) => {
      const sheet = await getOrActivateSheet(context, "DataslayerQueries");
    });
  } catch (error) {
    console.error("Error al visibilizar y redirigir a la hoja DataslayerQueries:", error);
  }
}

//Funcion show params
export async function showParams(queryId) {
  try {
    await Excel.run(async (context) => {
      const sheet = await getOrActivateSheet(context, "DataslayerQueries");
      const { values } = await loadWorksheet(context, "DataslayerQueries");
      const rowIndex = findQueryRow(values, queryId);

      if (rowIndex === -1) {
        console.error("Query ID no encontrado en la hoja DataslayerQueries");
        return;
      }

      const cellAddress = `A${rowIndex + 1}`;
      const selectedRange = sheet.getRange(cellAddress);
      selectedRange.select();
      await context.sync();
    });
  } catch (error) {
    console.error("Error al navegar al query:", error);
  }
}

export async function goToQueryResult(queryId) {
  try {
    await Excel.run(async (context) => {
      const { sheet, values } = await loadWorksheet(context, "DataslayerQueries");

      const rowIndex = findQueryRow(values, queryId);

      if (rowIndex === -1) {
        console.error("Query ID no encontrado en la hoja DataslayerQueries");
        return;
      }

      const { sheetName, tableRange } = getQueryInfo(values, rowIndex);

      if (!sheetName || !tableRange) {
        console.error("No se encontró una hoja o rango asociado con queryId:", queryId);
        return;
      }

      const targetSheet = context.workbook.worksheets.getItem(sheetName);
      targetSheet.activate();
      await context.sync();

      const tableRangeToSelect = targetSheet.getRange(tableRange);
      tableRangeToSelect.select();
      await context.sync();
    });
  } catch (error) {
    console.error("Error al navegar al resultado del query:", error);
  }
}

export const getDatasourcesWithQueries = async () => {
  try {
    return await Excel.run(async (context) => {
      const { sheet, values } = await loadWorksheet(context, "DataslayerQueries");
      if (!sheet || values.length === 0) {
        return [];
      }
      const datasourceColumnIndex = 11;
      const datasources = new Set();

      values.slice(1).forEach((row) => {
        const datasource = row[datasourceColumnIndex];
        if (datasource && !datasources.has(datasource)) {
          datasources.add(datasource);
        }
      });

      if (datasources.size === 0) {
        showDialog("The document cannot be duplicated", [
          "No valid queries stored in DataslayerQueries have been found.",
          "Please check that the sheet exists, there is at least one query and the connector is correct.",
          `It is possible the queries were deleted from the DataslayerQueries Sheet or that this sheet is corrupted. 
           To fix it, please follow these <a href='https://support.dataslayer.ai/why-should-i-not-modify-the-dataslayerqueries-sheet-and-what-is-it-for' target='_blank'>instructions</a>.`,
        ]);
        return [];
      }

      return Array.from(datasources);
    });
  } catch (error) {
    console.error("Error loading datasources:", error);
    showDialog("Error", ["Error loading datasources: " + error.message]);
    return [];
  }
};

async function requestAccountsToDuplicateDocument(datasources) {
  const userEmail = localStorage.getItem("DS-EXCEL-USERNAME");

  try {
    const params = {
      datasources: datasources,
      email: userEmail,
      service_id: config.SERVICE_ID,
    };
    const response = await getAllUserAccounts(params);

    if (response.ok) {
      const data = await response.json();
      if (data.task_id) {
        return await checkQueryStatus(data.task_id);
      }
    }
  } catch (error) {
    console.error("Error fetching accounts:", error);
    return { error: error.message };
  }
}

async function checkQueryStatus(taskId) {
  try {
    while (true) {
      const response = await getAccountsByTaskId({ taskId });
      if (response.ok) {
        const data = await response.json();
        if (data.ok && data.finished) {
          return { result: data.result };
        }
        if (!data.ok && data.finished) {
          return { title_error: data.title_error, error: data.error };
        }
      } else {
        throw new Error(`HTTP status ${response.status}`);
      }
      await new Promise((resolve) => setTimeout(resolve, 3000));
    }
  } catch (error) {
    return { error: `Error checking query status: ${error.message}` };
  }
}

async function createSettingsSheet(connections, accounts, refreshStatus) {
  try {
    await Excel.run(async (context) => {
      const workbook = context.workbook;
      let settingsSheet;

      const sheets = workbook.worksheets;
      try {
        settingsSheet = sheets.getItem("Settings");
        await context.sync();
      } catch (error) {
        settingsSheet = sheets.add("Settings");
        await context.sync();
      }

      settingsSheet.getRange("A1").values = [["Connections"]];
      settingsSheet.getRange("B1").values = [["Accounts"]];
      settingsSheet.getRange("C1").values = [["RefreshStatus"]];
      settingsSheet.getRange("A2").values = [[JSON.stringify(connections)]];
      settingsSheet.getRange("B2").values = [[JSON.stringify(accounts)]];
      settingsSheet.getRange("C2").values = [[refreshStatus]];

      settingsSheet.visibility = Excel.SheetVisibility.hidden;

      await context.sync();
    });
  } catch (error) {
    console.error("Error al crear la hoja 'Settings':", error);
  }
}

async function deleteSettingsSheet() {
  try {
    await Excel.run(async (context) => {
      const workbook = context.workbook;
      const sheets = workbook.worksheets;

      try {
        const settingsSheet = sheets.getItem("Settings");
        await context.sync();
        settingsSheet.delete();
        await context.sync();
      } catch (error) {
        console.warn("No se encontró la hoja 'Settings' para eliminar:", error);
      }
    });
  } catch (error) {
    console.error("Error al eliminar la hoja 'Settings':", error);
  }
}

async function duplicateWorkbookWithSettings() {
  try {
    await Excel.run(async (context) => {
      if (!(await closeExistingFile())) {
        return;
      }

      const file = await getFileAsync();
      if (!file) {
        return;
      }

      const base64Data = await getBase64FromFile(file);
      if (!base64Data) {
        return;
      }

      await file.closeAsync();
      await delay(7000);

      Excel.createWorkbook(base64Data);
    });
  } catch (error) {
    console.error("❌ Error al duplicar el workbook:", error);
  }
}

async function closeExistingFile(maxRetries = 3, delayMs = 5000) {
  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      const file = await getFileAsync();
      if (file) {
        await file.closeAsync();
        await delay(7000);
        return true;
      }
    } catch (error) {
      await delay(delayMs);
    }
  }
  return false;
}

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

async function mainWorkflow(connections, accounts, refreshStatus) {
  await createSettingsSheet(connections, accounts, refreshStatus);
  await duplicateWorkbookWithSettings();
  await deleteSettingsSheet();
}

export async function getFileAsync() {
  return new Promise((resolve, reject) => {
    Office.context.document.getFileAsync(Office.FileType.Compressed, { sliceSize: 65536 }, async (result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value);
      } else {
        reject(result.error);
      }
    });
  });
}

async function getBase64FromFile(file) {
  const sliceCount = file.sliceCount;
  let slicesReceived = 0;
  let fileContent = [];

  return new Promise((resolve, reject) => {
    function readSlice(sliceIndex) {
      file.getSliceAsync(sliceIndex, (sliceResult) => {
        if (sliceResult.status === Office.AsyncResultStatus.Succeeded) {
          const sliceData = sliceResult.value.data;
          const byteArray = new Uint8Array(sliceData);

          const textSlice = String.fromCharCode.apply(null, byteArray);
          fileContent.push(textSlice);
          slicesReceived++;

          if (slicesReceived === sliceCount) {
            const binaryString = fileContent.join("");
            const base64String = window.btoa(binaryString);

            fileContent = null;

            resolve(base64String);
          } else {
            readSlice(sliceIndex + 1);
          }
        } else {
          reject(sliceResult.error);
        }
      });
    }

    readSlice(0);
  });
}

export async function checkAndRefreshSettings() {
  try {
    await Excel.run(async (context) => {
      const workbook = context.workbook;
      const sheets = workbook.worksheets;

      sheets.load("items/name");
      await context.sync();

      const settingsSheet = sheets.items.find((sheet) => sheet.name === "Settings");
      if (!settingsSheet) {
        return;
      }

      const settingsRange = settingsSheet.getRange("A1:C2");
      settingsRange.load("values");
      await context.sync();

      const values = settingsRange.values;
      const connections = JSON.parse(values[1][0]);
      const accounts = JSON.parse(values[1][1]);
      const resetSheetContents = values[1][2];

      await updateDataslayerQueries(context, connections, accounts, resetSheetContents);

      settingsSheet.delete();
      await context.sync();
    });
  } catch (error) {
    console.error("Error al verificar y actualizar la hoja 'Settings':", error);
  }
}

async function updateDataslayerQueries(context, connections, accounts, resetSheetContents) {
  try {
    const workbook = context.workbook;
    const sheet = workbook.worksheets.getItem("DataslayerQueries");

    sheet.load("visibility");
    await context.sync();

    // if (sheet.visibility === Excel.SheetVisibility.hidden) {
    //   sheet.visibility = Excel.SheetVisibility.visible;
    //   await context.sync();
    // }

    const range = sheet.getUsedRange();
    range.load("values");
    await context.sync();

    const values = range.values;

    const newAccounts = [];
    const newConnections = [];

    for (let row = 1; row < values.length; row++) {
      const datasource = values[row][11];
      if (datasource) {
        if (connectorsWithoutAccounts.includes(datasource)) {
          newAccounts.push([values[row][17]]);
          newConnections.push([values[row][37]]);
        } else {
          newAccounts.push([JSON.stringify(accounts[datasource])]);
          newConnections.push([JSON.stringify(connections[datasource])]);
        }
      }
    }

    if (resetSheetContents) {
      const sheets = workbook.worksheets;
      sheets.load("items");
      await context.sync();

      sheets.items.forEach((otherSheet) => {
        if (otherSheet.name !== "DataslayerQueries") {
          const usedRange = otherSheet.getUsedRange();
          if (usedRange) {
            usedRange.clear();
          }
        }
      });
      await context.sync();
    }

    if (newAccounts.length > 0 && newConnections.length > 0) {
      const accountsRange = sheet.getRangeByIndexes(1, 17, newAccounts.length, 1);
      const connectionsRange = sheet.getRangeByIndexes(1, 37, newConnections.length, 1);

      accountsRange.values = newAccounts;
      connectionsRange.values = newConnections;

      await context.sync();
    }
  } catch (error) {
    console.error("Error al actualizar 'DataslayerQueries':", error);
  }
}

export async function handleWorksheetNameChanged(eventArgs, loadQueriesFromSheet) {
  try {
    const { nameBefore, nameAfter } = eventArgs;

    await Excel.run(async (context) => {
      const workbook = context.workbook;
      const dataslayerSheet = workbook.worksheets.getItem("DataslayerQueries");

      dataslayerSheet.load("visibility");
      await context.sync();

      // if (dataslayerSheet.visibility === Excel.SheetVisibility.hidden) {
      //   dataslayerSheet.visibility = Excel.SheetVisibility.visible;
      //   await context.sync();
      // }

      const range = dataslayerSheet.getUsedRange();
      range.load("values");
      await context.sync();
      const columnBIndex = 1;
      const columnBValues = range.values.map((row) => row[columnBIndex]);

      if (!range.values || range.values.length === 0) {
        return;
      }

      const updatedValues = range.values.map((row) => {
        if (row[columnBIndex] === nameBefore) {
          row[columnBIndex] = nameAfter;
        }
        return row;
      });

      // Escribir los valores actualizados en la hoja
      const updatedRange = dataslayerSheet.getRangeByIndexes(0, 0, updatedValues.length, updatedValues[0].length);
      updatedRange.values = updatedValues;
      await context.sync();

      // Actualizar el registro de hojas
      if (registeredSheets.has(nameBefore)) {
        registeredSheets.delete(nameBefore);
      }
      registeredSheets.add(nameAfter);

      if (typeof loadQueriesFromSheet === "function") {
        loadQueriesFromSheet();
      }
    });
  } catch (error) {
    console.error("Error handling worksheet name change:", error);
  }
}

const registeredSheets = new Set();

// Función para obtener los nombres de todas las hojas en el workbook
export async function getWorkbookSheets() {
  try {
    return await Excel.run(async (context) => {
      const sheets = context.workbook.worksheets;
      sheets.load("items/name");
      await context.sync();

      return sheets.items.map((sheet) => sheet.name);
    });
  } catch (error) {
    console.error("Error fetching workbook sheets:", error);
    return [];
  }
}

// Función para registrar eventos de cambio de nombre
export async function subscribeToWorksheetNameChanges(loadQueriesFromSheet) {
  try {
    await Excel.run(async (context) => {
      const sheets = context.workbook.worksheets;
      sheets.load("items/name");
      await context.sync();
      sheets.items.forEach((sheet) => {
        if (!registeredSheets.has(sheet.name)) {
          sheet.onNameChanged.add((eventArgs) => handleWorksheetNameChanged(eventArgs, loadQueriesFromSheet));
          registeredSheets.add(sheet.name);
        }
      });
    });
  } catch (error) {
    console.error("Error subscribing to worksheet name changes:", error);
  }
}

export async function subscribeToNewSheetAdditions(onNewSheetAdded) {
  try {
    await Excel.run(async (context) => {
      const workbook = context.workbook;

      workbook.worksheets.onAdded.add(async () => {
        if (onNewSheetAdded) {
          await onNewSheetAdded();
        }
      });

      await context.sync();
    });
  } catch (error) {
    console.error("Error subscribing to new sheet additions:", error);
  }
}
