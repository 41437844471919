import * as React from "react";
import { makeStyles, Button, Image, typographyStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  startBody: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    padding: "50px",
  },
  text: typographyStyles.subtitle1,
  explainDS: {
    ...typographyStyles.body2,
    textAlign: "center",
    marginBottom: "15px",
  },
  explain: {
    ...typographyStyles.body1,
    textAlign: "center",
  },
  contactUs: {
    ...typographyStyles.caption1,
    textAlign: "center",
    lineHeight: "15px",
  },
});

const StartPage = (props) => {
  const styles = useStyles();
  const { login, errorMessage } = props;
  return (
    <div id="start-body" className={styles.startBody}>
      <div className="flex flex-col items-center">
        <span className={styles.text}>Welcome to</span>
        {/* {errorMessage && <span>An error has occurred</span>} */}
        {/* <span className={styles.explain}>
          {errorMessage ? errorMessage : "Please log in to continue enjoying the Dataslayer experience in Excel"}
        </span> */}
      </div>
      <div>
        <Image alt="Dataslayer Logo" src="../assets/dataslayer-logo.svg" height={50} width={200} />
      </div>
      <div className="flex flex-col items-center">
        <span className={styles.explainDS}>
          We bring <b>all your marketing data into one place directly within Excel</b>, saving you time and simplifying
          reporting.
        </span>
        <span className={styles.explainDS}>
          Sign up <b>to access data blending features, automate reports, and gain powerful insights.</b>

        </span>
      </div>
      <div>
        <Button className="w-full" appearance="primary" onClick={login}>
          Sign up
        </Button>
      </div>
    </div>
  );
};

export default StartPage;
