import * as React from "react";
import { Button, makeStyles, Spinner } from "@fluentui/react-components";
import { useQuery } from "react-query";
import PropTypes from "prop-types";
import { HashRouter as Router } from "react-router-dom";

import { getDStoken, getUserInfo, getUserToken } from "../../services/login";

import Content from "./Content";
import Header from "./Header";
import Footer from "./Footer";
import StartPage from "./StartPageLogin";
import { config } from "../../Constants";
import {
  checkAndRefreshSettings,
  getWorkbookSheets,
  handleDuplicateFile,
  handleGettingStarted,
  handleSchedule,
  handleTemplateGallery,
  handleUserRegister,
  manageQueries,
  subscribeToNewSheetAdditions,
  subscribeToWorksheetNameChanges,
} from "../../utilities/excel";
import { useQueryRefresh } from "../../utilities/useQueryRefresh";
import { useDialog } from "../context/DialogContext";
import { useQueryContext } from "../context/QueryContext";

import { getLoginInfo } from "../../services/connector";
import { checkAndUpdateFromOneDrive, generateUserCode, syncWithOneDrive } from "../../utilities/office-apis-helpers";
import { useMessage } from "../context/MessageContext";

const useStyles = makeStyles({
  root: {
    minHeight: "80vh",
  },
});

const App = (props) => {
  const styles = useStyles();
  const storedSecret = localStorage.getItem("DS-SECRET");
  const storedToken = localStorage.getItem("DS-EXCEL-TOKEN");
  const storedDSToken = localStorage.getItem("DS-TOKEN");
  const storedUserName = localStorage.getItem("DS-EXCEL-USERNAME");
  const [authStatus, setAuthStatus] = React.useState("loginInProcess");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [token, setToken] = React.useState(storedToken);
  const [DSToken, setDSToken] = React.useState(storedDSToken);
  const [userName, setUserName] = React.useState(storedUserName);
  const [refreshSchedules, setRefreshSchedules] = React.useState(false);
  const [oneDriveAuthorization, setOneDriveAuthorization] = React.useState(false);
  const [secret, setSecret] = React.useState(storedSecret);
  const [sheets, setSheets] = React.useState([]);
  const [currentUsage, setCurrentUsage] = React.useState(0);
  const { setDialogOpen } = useDialog();
  const { loadQueriesFromSheet, queries } = useQueryContext();
  const { updateMessage } = useMessage();
  const { handleRefreshAll, handleRefreshActiveSheetQueries, getSchedulesRefetch } = useQueryRefresh(
    setDialogOpen,
    loadQueriesFromSheet,
    DSToken,
    userName,
    updateMessage
  );
  // const { getSchedulesRefetch } = useQueryRefresh({ userName, updateMessage });

  const abortController = new AbortController();

  // React.useEffect(() => {
  //   const syncInterval = setInterval(
  //     () => {
  //       syncWithOneDrive(updateMessage);
  //     },
  //     10 * 60 * 1000
  //   );
  //   return () => clearInterval(syncInterval);
  // }, []);

  // React.useEffect(() => {
  //   Office.onReady((info) => {
  //     console.log("Office.onReady", info, Office.HostType.Excel);
  //     if (info.host === Office.HostType.Excel) {
  //       checkAndUpdateFromOneDrive(updateMessage);
  //     }
  //   });
  // }, []);

  const actionsRequiringLogin = new Set([
    "refreshAllQueries",
    "refreshActiveSheet",
    "refreshAndEmail",
    "duplicateFile",
    "gettingStarted",
  ]);

  React.useEffect(() => {
    const handleRibbonClick = (event) => {
      const { action } = event.detail;
      executeRibbonAction(action);
    };

    window.addEventListener("ribbonButtonClick", handleRibbonClick);

    return () => {
      window.removeEventListener("ribbonButtonClick", handleRibbonClick);
    };
  }, []);

  React.useEffect(() => {
    const storedRibbonAction = localStorage.getItem("ribbonAction");
    if (storedRibbonAction) {
      if (actionsRequiringLogin.has(storedRibbonAction) && authStatus !== "loggedIn") {
        return;
      }
      executeRibbonAction(storedRibbonAction);
      localStorage.removeItem("ribbonAction");
    }
  }, [authStatus]);

  const executeRibbonAction = (action) => {
    switch (action) {
      case "manageQueries":
        manageQueries();
        break;
      case "refreshAllQueries":
        handleRefreshAll(queries);
        break;
      case "refreshActiveSheet":
        handleRefreshActiveSheetQueries(queries);
        break;
      case "duplicateFile":
        handleDuplicateFile();
        break;
      case "templateGallery":
        handleTemplateGallery();
        break;
      case "gettingStarted":
        handleGettingStarted();
        break;
      case "refreshAndEmail":
        setRefreshSchedules(false);
        handleSchedule(updateMessage, null, async () => {
          await getSchedulesRefetch();
          setRefreshSchedules(true);
        });
        break;
      default:
        break;
    }
  };

  const login = async () => {
    setAuthStatus("loginInProcess");
    const codigo = generateUserCode(32);
    localStorage.setItem("DS-SECRET", codigo);
    setSecret(codigo);
    const authUrl = `${config.M_AUTHORITY}/authorize?client_id=${config.CLIENT_ID}&response_type=${config.RESPONSE_TYPE}&redirect_uri=${config.REDIRECT_URI}?secret=${encodeURIComponent(codigo)}&code_challenge=${config.CODE_CHALLENGE}&code_challenge_method=${config.CODE_CHALLENGE_METHOD}&scope=${config.SCOPES.join("+")}&prompt=${config.PROMPT}`;
    window.open(authUrl, "_blank");
  };

  const logout = async () => {
    setAuthStatus("notLoggedIn");
    localStorage.clear();
  };

  React.useEffect(() => {
    const token = localStorage.getItem("DS-TOKEN");
    if (token) {
      setDSToken(token);
    }
    checkAndRefreshSettings();
  }, []);

  const { data, refetch: getDSTokenRefetch } = useQuery(
    ["dsToken", token, userName],
    async () => {
      setAuthStatus("loginInProcess");
      const response = await getDStoken({ token, username: userName });
      if (response?.ok) {
        return await response.json();
      } else {
        // const errorData = await response.json();
        // throw errorData.detail;
        throw await response.json();
      }
    },
    {
      staleTime: Infinity,
      enabled: !!token,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        setAuthStatus("loggedIn");
        setDSToken(data.token);
        setUserName(data.username);
        localStorage.setItem("DS-TOKEN", data.token);
        localStorage.setItem("DS-EXCEL-USERNAME", data.username ?? "");
        setErrorMessage("");
      },
      onError: async (error) => {
        // localStorage.clear();
        setToken(null);
        setErrorMessage(error.message);
        setUserName(error.email);
        localStorage.setItem("DS-EXCEL-USERNAME", error.email ?? "");

        if (error.error_code === "USER_NOT_REGISTERED") {
          await handleRegistrationFlow(error.email);
        } else {
          setAuthStatus("notLoggedIn");
        }
      },
    }
  );

  const handleRegistrationFlow = async (email) => {
    try {
      const finalLoginInfo = await waitForLoginInfo();
      if (!finalLoginInfo?.countries) {
        throw new Error("No se pudo obtener la información necesaria para el registro.");
      }
      setTimeout(() => {
        setAuthStatus("notLoggedIn");
        handleUserRegister(email, finalLoginInfo.countries, setAuthStatus, setErrorMessage, getDSTokenRefetch);
      }, 5000);
    } catch (error) {
      setErrorMessage("No se pudo iniciar el flujo de registro.");
      setAuthStatus("notLoggedIn");
    }
  };

  const waitForLoginInfo = async () => {
    while (!loginInfo?.countries) {
      const result = await refetchLoginInfo();
      if (result?.data?.countries) {
        return result.data;
      }
    }
    return loginInfo;
  };

  const handleTimeout = () => {
    abortController.abort();
    setAuthStatus("notLoggedIn");
    setSecret(null);
    localStorage.clear();
  };

  const { data: tokenData, refetch: refetchToken } = useQuery(
    ["userToken", secret],
    async () => {
      setAuthStatus("loginInProcess");
      const response = await getUserToken({ code: secret, signal: abortController.signal });

      if (response?.ok) {
        const data = await response.json();
        return data;
      } else {
        const errorData = await response.json();
        throw new Error(errorData?.detail || "Unknown error occurred");
      }
    },
    {
      enabled: !!secret,
      staleTime: Infinity,
      refetchInterval: 3000,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setAuthStatus("loggedIn");
        setSecret(null);
        setToken(data.access_token);
        localStorage.setItem("DS-EXCEL-TOKEN", data.access_token);
        localStorage.setItem("DS-EXCEL-REFRESH-TOKEN", data.refresh_token);
        localStorage.setItem("DS-EXPIRATION-TIME", Date.now() + 3600 * 1000);
        localStorage.removeItem("DS-SECRET");
        setErrorMessage("");
      },
      onError: (error) => {
        setErrorMessage(error.message);
        console.error("Error fetching tokens:", error);
      },
    }
  );

  const { data: loginInfo, refetch: refetchLoginInfo } = useQuery(
    ["loginInfo", userName, config.SERVICE_ID],
    async () => {
      const response = await getLoginInfo(userName, config.SERVICE_ID);

      if (response?.ok) {
        return await response.json();
      }

      throw new Error(`Error fetching loginInfo: ${response.status} ${response.statusText}`);
    },
    { staleTime: Infinity, enabled: !!userName }
  );

  const { data: user, refetch: refetchUserInfo } = useQuery(
    ["user-info", DSToken],
    async () => {
      const response = await getUserInfo({
        token: DSToken,
        service_id: config.SERVICE_ID,
      });
      if (response?.ok) return await response.json();
      throw new Error("Error fetching models");
    },
    {
      staleTime: Infinity,
      enabled: !!DSToken,
      onSuccess: (data) => setCurrentUsage(data.current_usage),
    }
  );

  React.useEffect(() => {
    setAuthStatus("notLoggedIn");
    // if (!storedToken || !storedUserName) {
    //   // login();
    // }
  }, []);

  React.useEffect(() => {
    const initializeSheetEvents = async () => {
      try {
        const initialSheets = await getWorkbookSheets();
        setSheets(initialSheets);

        // Suscribirse a cambios de nombre en hojas
        await subscribeToWorksheetNameChanges(async () => {
          const updatedSheets = await getWorkbookSheets();
          setSheets(updatedSheets);
        });

        // Suscribirse a la adición de nuevas hojas
        await subscribeToNewSheetAdditions(async () => {
          const updatedSheets = await getWorkbookSheets();
          setSheets(updatedSheets);
          loadQueriesFromSheet();
        });
      } catch (error) {
        console.error("Error initializing sheet events:", error);
      }
    };

    initializeSheetEvents();
  }, []);

  React.useEffect(() => {
    subscribeToWorksheetNameChanges(loadQueriesFromSheet);
  }, [sheets]);

  React.useEffect(() => {
    if (authStatus === "loginInProcess") {
      const timeout = setTimeout(handleTimeout, 300000);
      return () => clearTimeout(timeout);
    }
    if (authStatus === "loggedIn") {
      Office.onReady((info) => {
        if (info.host === Office.HostType.Excel) {
          checkAndUpdateFromOneDrive(updateMessage);
        }
      });
    }
  }, [authStatus]);

  let body;

  if (authStatus === "notLoggedIn") {
    body = <StartPage login={login} errorMessage={errorMessage} />;
  } else if (authStatus === "loginInProcess") {
    body = (
      <div className="flex flex-col justify-center h-screen items-center gap-4">
        <Spinner labelPosition="below" label="Authenticating, please wait" />
        <Button
          onClick={() => {
            abortController.abort();
            setAuthStatus(!oneDriveAuthorization ? "notLoggedIn" : "loggedIn");
            setSecret(null);
            !oneDriveAuthorization ? localStorage.clear() : null;
          }}
          className="w-2/4"
          appearance="primary"
        >
          Cancel
        </Button>
      </div>
    );
  } else if (authStatus === "registerInProcess") {
    body = (
      <div className="flex flex-col justify-center h-screen">
        <Spinner labelPosition="below" label="Register in process, please wait" />
      </div>
    );
  } else {
    body = (
      <>
        <Router basename="/">
          <Header title={props.title} logout={logout} refetchUserInfo={refetchUserInfo} />
          <Content
            loginInfo={loginInfo || []}
            setLoginInfo={refetchLoginInfo}
            setSecret={setSecret}
            setOneDriveAuth={setOneDriveAuthorization}
            refreshSchedules={refreshSchedules}
            setCurrentUsage={setCurrentUsage}
            refetchUserInfo={refetchUserInfo}
          />
        </Router>
        <Footer userName={userName} user={user} logout={logout} />
      </>
    );
  }

  return (
    <>
      {/* {errorMessage ? <div>{errorMessage}</div> : null} */}
      <div className={styles.root}>{body}</div>
    </>
  );
};

App.propTypes = {
  title: PropTypes.string,
};

export default App;
