import React from "react";
import { Routes, Route } from "react-router-dom";
import QueryManager from "./QueryManager";
import MainAccordion from "./MainAccordion";

const Content = ({ loginInfo, setLoginInfo }) => {
  return (
    <div id="main-content" className="mb-24">
      <Routes>
        <Route exact path="/" element={<QueryManager setLoginInfo={setLoginInfo} />} />
        <Route
          path="add-query"
          element={<MainAccordion title="CONNECTORS" loginInfo={loginInfo} setLoginInfo={setLoginInfo} />}
        />
      </Routes>
    </div>
  );
};

export default Content;
