import React, { createContext, useState, useContext } from "react";

const MessageContext = createContext();

export const useMessage = () => useContext(MessageContext);

export const MessageProvider = ({ children }) => {
  const [message, setMessage] = useState("");

  const updateMessage = (newMessage) => {
    setMessage(newMessage);
  };

  return <MessageContext.Provider value={{ message, updateMessage }}>{children}</MessageContext.Provider>;
};
