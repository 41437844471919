import { fetchAPI } from "./base";

export function getRefreshedToken({ refresh_token }) {
  return fetchAPI({
    endpoint: `microsoft/refresh-token`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ refresh_token }),
  });
}

export function isTokenExpiring() {
  const expirationTime = localStorage.getItem("DS-EXPIRATION-TIME");
  if (!expirationTime) return true;
  const margin = 5 * 60 * 1000;
  return Date.now() > expirationTime - margin;
}

export async function refreshAccessToken() {
  const refreshToken = localStorage.getItem("DS-EXCEL-REFRESH-TOKEN");
  if (!refreshToken) {
    throw new Error("No refresh token found. User needs to log in again.");
  }
  try {
    const data = await getRefreshedToken({ refresh_token: refreshToken });
    if (data.ok) {
      const { access_token, refresh_token } = await data.json();
      localStorage.setItem("DS-EXCEL-TOKEN", access_token);
      localStorage.setItem("DS-EXPIRATION-TIME", Date.now() + 3600 * 1000);
      localStorage.setItem("DS-EXCEL-REFRESH-TOKEN", refresh_token);
      return access_token;
    } else {
      localStorage.clear();
      window.location.reload();
      throw new Error("Invalid token data received from refresh endpoint");
    }
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
}

export async function ensureValidAccessToken() {
  if (isTokenExpiring()) {
    await refreshAccessToken();
  }
  const token = localStorage.getItem("DS-EXCEL-TOKEN");
  return token;
}
