import { Field, Input, Select, Textarea } from "@fluentui/react-components";
import React, { useState, useCallback } from "react";
import { Controlled as CodeMirror } from "react-codemirror2";

const SelectField = ({ id, label, value, options, onChange }) => (
  <Field className="flex flex-col mx-2 my-3" label={label}>
    <Select id={id} value={value} onChange={onChange}>
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </Select>
  </Field>
);

const TextareaField = ({ id, label, value, onChange }) => (
  <Field className="flex flex-col mx-2 my-3" label={label}>
    <Textarea id={id} value={value} onChange={onChange} />
  </Field>
);

const InputField = ({ id, label, value, onChange }) => (
  <Field className="flex flex-col mx-2 my-3" label={label}>
    <Input type="text" id={id} value={value} onChange={onChange} />
  </Field>
);

const CodeMirrorField = ({ id, label, value = "", mode = "sql", onChange }) => (
  <Field className="flex flex-col mx-2 my-3" label={label}>
    <CodeMirror
      id={id}
      value={value || ""}
      options={{
        mode: mode,
        theme: "eclipse",
        lineNumbers: true,
      }}
      onBeforeChange={(editor, data, value) => {
        onChange({ target: { value } });
      }}
    />
  </Field>
);

const DatabaseTables = ({ tables, onTableClick }) => (
  <table className="table table-container">
    <thead>
      <tr>
        <th>TABLES</th>
      </tr>
    </thead>
    <tbody className="block">
      {Object.keys(tables).map((tableName, index) => (
        <tr className="db-items" key={index} onClick={() => onTableClick(tableName)}>
          <td className="db-text">{tableName}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const DatabaseColumns = ({ tableName, columns }) => (
  <>
    <table className="table table-container">
      <thead>
        <tr>
          <th>COLUMNS IN {tableName.toUpperCase()}</th>
        </tr>
      </thead>
      <tbody className="block">
        {columns.map((column, index) => (
          <tr className="db-items" key={index}>
            <td className="db-text">{column}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </>
);

const FormSection = ({ section, values, handleChange, selectedQueryType }) => {
  if (selectedQueryType && section.visibleOn && section.visibleOn !== selectedQueryType) {
    return null;
  }

  return (
    <div className="form-section">
      {(() => {
        const commonProps = {
          id: section.id,
          label: section.name,
          value: values[section.id] || "",
          onChange: (e) => handleChange(section.id, e.target.value),
        };

        switch (section.type) {
          case "select":
            return <SelectField {...commonProps} options={section.values} />;
          case "textarea":
            return <TextareaField {...commonProps} />;
          case "input":
            return <InputField {...commonProps} />;
          case "codemirror":
            return <CodeMirrorField {...commonProps} mode={section.mode} />;
          default:
            return null;
        }
      })()}
    </div>
  );
};

const DynamicForm = ({ sections, onSubmit, onValuesChange, options, dynamicFormValues, database }) => {
  const [values, setValues] = useState(dynamicFormValues || { queryType: "" });
  const initialQueryType = sections.find((section) => section.id === "queryType")?.values[0].id;
  const initialDocType = sections.find((section) => section.id === "type")?.values[0].id;
  const [selectedTable, setSelectedTable] = useState(null);
  const [columns, setColumns] = useState([]);

  React.useEffect(() => {
    if (!values.queryType) {
      setValues((prevValues) => ({
        ...prevValues,
        queryType: initialQueryType,
        type: initialDocType,
      }));
    } else {
      onValuesChange && onValuesChange(values);
    }
  }, [initialQueryType, values.queryType, onValuesChange]);

  const handleChange = useCallback(
    (name, value) => {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
      onValuesChange && onValuesChange({ ...values, [name]: value });
    },
    [values, onValuesChange]
  );

  const handleTableClick = (tableName) => {
    setSelectedTable(tableName);
    setColumns(database[tableName]);
  };
  return (
    <>
      {sections &&
        sections.map((section, sectionIndex) => (
          <FormSection
            key={sectionIndex}
            section={section}
            values={values}
            handleChange={handleChange}
            selectedQueryType={values.queryType}
          />
        ))}
      {options &&
        options.map((option, optionIndex) => (
          <FormSection
            key={optionIndex}
            section={option}
            values={values}
            handleChange={handleChange}
            selectedQueryType={values.queryType}
          />
        ))}

      {sections && sections.find((option) => option.name === "table") && (
        <div className="flex flex-row justify-between p-2">
          <DatabaseTables tables={database} onTableClick={handleTableClick} />
          {selectedTable && <DatabaseColumns tableName={selectedTable} columns={columns} />}
        </div>
      )}
    </>
  );
};

export default DynamicForm;
