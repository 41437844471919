import * as React from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  makeStyles,
  Button,
  Caption2,
} from "@fluentui/react-components";
import { useMutation } from "react-query";
import {
  TableFilled,
  LinkMultipleFilled,
  ArrowLeft16Filled,
  PersonCircleFilled,
  TextBulletListSquareFilled,
  QuestionCircleFilled,
  SettingsFilled,
} from "@fluentui/react-icons";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useQueryContext } from "../context/QueryContext";
import ConnectorsAccordion from "./ConnectorsAccordion";
import {
  deleteTaskId,
  generateBlendTable,
  generateTable,
  getAccountsByTaskId,
  getBlendMetrics,
  getConnectors,
  getMetrics,
} from "../../services/connector";
import AccountsList from "./AccountsList";
import DataTableAccordion from "./DataTableAccordion";
import OptionsAccordion from "./OptionsAccordion";
import { useDialog } from "../context/DialogContext";
import { calculateDateRange, getInitialEndDate, getInitialStartDate, getSelectedDateRangeOption, onFormatDate } from "../../utilities/formatDates";
import {
  checkRangeOverlap,
  generateUUID,
  isDataslayerQueriesActive,
  showDialog,
  writeDataToExcel,
} from "../../utilities/excel";
import RunningDialog from "./RunningDialog";
import { config } from "../../Constants";
import ReportConfigAccordion from "./ReportConfigAccordion";
import DynamicForm from "./DynamicFormAccordion";
import { buildQueryRequest } from "../../utilities/parametersForSpecialConnectors";
import {
  connectorConfigurations,
  connectorsWithAccounts,
  loginOptionConnectors,
} from "../../utilities/connectorsConfiguration";
import {
  adaptConnectorData,
  generateBlendRequestBody,
  generateDefaultRequestBody,
  processAccounts,
} from "../../utilities/adapters";
import { useGenerateTable } from "../../utilities/useGenerateTable";

const useStyles = makeStyles({
  accordionHeader: {
    backgroundColor: "lightgray",
  },
  generateTable: {
    paddingRight: "15px",
    paddingLeft: "15px",
  },
  padding0: {
    padding: "0px!important",
  },
  // marginPanel: {
  //   marginBottom: "80px!important",
  // },
  accountsPanel: { height: "100vh" },
  infoButton: { right: "40px", position: "absolute" },
  modify: { marginTop: "10px", fontStyle: "italic", fontWeight: "bold" },
});

const MainAccordion = ({ loginInfo, setLoginInfo, setCurrentUsage, refetchUserInfo }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { dialogOpen, setDialogOpen } = useDialog();
  const {
    addQuery,
    getSelectedQuery,
    updateQuery,
    setSelectedQueryId,
    isDuplicating,
    setIsDuplicating,
    queries,
    setIsEditing,
    isEditing,
  } = useQueryContext();
  const token = localStorage.getItem("DS-TOKEN");
  const userName = localStorage.getItem("DS-EXCEL-USERNAME");

  const [stablishedConnection, setStablishedConnection] = React.useState(false);
  const [taskId, setTaskId] = React.useState(null);
  const [hasWrittenData, setHasWrittenData] = React.useState(false);
  const [tableTaskId, setTableTaskId] = React.useState(null);
  const [accounts, setAccounts] = React.useState([]);
  const [connectedConnectorName, setConnectedConnectorName] = React.useState("");
  const [entryConnector, setEntryConnector] = React.useState(null);

  const [infoConnector, setInfoConnector] = React.useState(null);
  const [selectedDimsOptions, setSelectedDimsOptions] = React.useState([]);
  const [selectedDimsColsOptions, setSelectedDimsColsOptions] = React.useState([]);
  const [selectedMetricsOptions, setSelectedMetricsOptions] = React.useState([]);
  const [selectedAccounts, setSelectedAccounts] = React.useState([]);
  const [selectedConnectors, setSelectedConnectors] = React.useState([]);
  const [selectedReportOption, setSelectedReportOption] = React.useState(null);
  const [selectedSpecialSettings, setSelectedSpecialSettings] = React.useState({});
  const [configuration, setConfiguration] = React.useState([]);

  const [previousReportType, setPreviousReportType] = React.useState(null);
  const [appliedFilters, setAppliedFilters] = React.useState([]);
  const [appliedMetricsSorts, setAppliedMetricsSorts] = React.useState([]);
  const [appliedDimsSorts, setAppliedDimsSorts] = React.useState([]);
  const [dates, setDates] = React.useState({
    start_date: getInitialStartDate(),
    end_date: getInitialEndDate(),
    date_range_type: "custom",
  });
  const [compareDates, setCompareDates] = React.useState({});
  const [limits, setLimits] = React.useState({
    limitRows: 0,
    limitCols: 0,
  });
  const startTimeRef = React.useRef(null);
  const [selectedQuery, setSelectedQuery] = React.useState(null);

  const connectorsRef = React.useRef(null);
  const [expandedItem, setExpandedItem] = React.useState(null);
  const [dynamicFormValues, setDynamicFormValues] = React.useState({});

  const [processedAccounts, setProcessedAccounts] = React.useState([]);

  const [requestBody, setRequestBody] = React.useState(null);
  const [fallbackConfig, setFallbackConfig] = React.useState(null);

  const [additionalDimensions, setAdditionalDimensions] = React.useState([]);
  const [additionalMetrics, setAdditionalMetrics] = React.useState([]);
  let abortController = React.useRef(new AbortController());

  const { checkAvailableQueries, isLoading } = useGenerateTable(
    entryConnector,
    token,
    userName,
    showDialog,
    setTableTaskId,
    setDialogOpen
  );

  React.useEffect(() => {
    if (accounts && entryConnector) {
      const result = processAccounts(entryConnector, accounts);
      setProcessedAccounts(result);
    }
  }, [accounts]);
  //Fetch connectors
  const { data, refetch: getConnectorsRefetch } = useQuery(
    ["datasources", token],
    async () => {
      const response = await getConnectors({ token });
      if (response?.ok) {
        return await response.json();
      }

      throw new Error("Error fetching models");
    },
    { staleTime: Infinity }
  );
  // Fetch metrics
  const { data: allMetrics } = useQuery(
    ["metrics", entryConnector, selectedReportOption],
    async () => {
      const fetchConnector = loginOptionConnectors.find((connector) => connector.key === entryConnector);
      let response;
      if (fetchConnector) {
        response = loginInfo;
      } else if (entryConnector.startsWith("blend_")) {
        response = await getBlendMetrics({ hash_id: entryConnector, token, service_id: config.SERVICE_ID });
      } else if (entryConnector === "bigquery") {
        response = null;
      } else {
        response = await getMetrics({
          entryConnector,
          token,
          call_type: connectorConfigurations[entryConnector] ? selectedReportOption : null,
        });
      }
      if (fetchConnector && response) {
        const options = response;
        return { options: options[fetchConnector.name] };
      }
      if (response?.ok) {
        const responseData = await response.json();
        const adaptedData = adaptConnectorData(entryConnector, responseData);
        return adaptedData;
      }
      if (!response) {
        return { metrics: [], dimensions: [], filters: [], report_options: [] };
      }
      throw new Error("Error fetching metrics");
    },
    {
      enabled: !!entryConnector,
      staleTime: Infinity,
    }
  );

  // const dimensions = allMetrics?.result?.dimensions || [];
  // const metrics = allMetrics?.result?.metrics || [];
  const options = allMetrics?.options || [];
  const filters = allMetrics?.filters || [];
  const reportOptions = allMetrics?.report_options || [];

  const [dimensions, setCombinedDimensions] = React.useState([]);
  const [metrics, setCombinedMetrics] = React.useState([]);

  React.useEffect(() => {
    const updatedMetrics = { ...allMetrics?.result?.metrics };
    const updatedDimensions = { ...allMetrics?.result?.dimensions };
    if (Array.isArray(additionalMetrics) && additionalMetrics.length > 0) {
      updatedMetrics["Custom Events"] = [...additionalMetrics];
    }

    if (Array.isArray(additionalDimensions) && additionalDimensions.length > 0) {
      updatedDimensions["Custom Events"] = [...additionalDimensions];
    }

    setCombinedMetrics(updatedMetrics);
    setCombinedDimensions(updatedDimensions);
  }, [allMetrics, additionalMetrics, additionalDimensions]);

  React.useEffect(() => {
    if (Object.keys(dimensions).length > 0 || Object.keys(metrics).length > 0) {
      setFallbackConfig(null);
    } else {
      setConfiguration(configuration);
      setFallbackConfig(configuration?.accordions);
    }
  }, [dimensions, metrics, configuration, entryConnector]);

  // Mutation for fetching accounts by TaskId
  const mutation = useMutation(
    async ({ taskId }) => {
      if (taskId) {
        setDialogOpen(true);
        const response = await getAccountsByTaskId({ taskId });
        if (response?.ok) return await response.json();
        throw new Error("Error fetching accounts");
      }
    },
    {
      enabled: !!taskId,
      onSuccess: (data) => {
        if (data?.finished && data?.ok) {
          setAccounts(data.result.accounts);
          setDialogOpen(false);
          setStablishedConnection(true);
          setTaskId(null);
        } else if (!data?.ok && data?.finished) {
          showDialog(data.title_error, data.error);
          setDialogOpen(false);
          setTaskId(null);
        } else {
          setTimeout(() => {
            mutation.mutate({ taskId });
          }, 3000);
        }
      },
      onError: (error) => {
        console.error("Error fetching accounts:", error);
        setTaskId(null);
        setDialogOpen(false);
      },
    }
  );
  React.useEffect(() => {
    if (taskId) {
      mutation.mutate({ taskId });
    }
  }, [taskId]);

  // Handle Connection Change
  const handleConnectionChange = React.useCallback(
    (connectorName, entryConnector) => {
      setConnectedConnectorName(connectorName);
      setEntryConnector(entryConnector);
      if (!selectedQuery) reinitializeValues();
    },
    [selectedQuery]
  );

  // Generate Table
  const handleGenerateTable = React.useCallback(async () => {
    try {
      const isDSActive = await isDataslayerQueriesActive();
      if (!selectedQuery && isDSActive) {
        showDialog("The query cannot be created", [
          "You cannot insert data into the DataslayerQueries sheet. Please change to another sheet and try again",
        ]);
        return;
      }

      const registeredRanges = queries.map((q) => ({
        sheetName: q.sheetName,
        rangeAddress: q.rangeAddress,
      }));

      const isOverlapping = await checkRangeOverlap(registeredRanges);
      if (!selectedQuery && isOverlapping) {
        showDialog("Warning message", [
          "You cannot create a new query in this cell because it would overwrite an existing query stored in DataslayerQueries.",
          "If you want to modify or delete the current query, click on one of the cells in the table and then select 'Modify' or 'Delete data & query'.",
        ]);
        return;
      }
      setHasWrittenData(false);
      const { rangeType, lastItemsInRange, includeCurrent } = await getSelectedDateRangeOption(dates.date_range_type);
      const { startDate, endDate } = await calculateDateRange(rangeType, lastItemsInRange, includeCurrent, dates);
      const formattedDates = {
        date_range_type: dates.date_range_type,
        ...(dates.date_range_type === "custom"
          ? { start_date: dates.start_date, end_date: dates.end_date }
          : { start_date: onFormatDate(startDate), end_date: onFormatDate(endDate) }),
      };
      let body;
      if (entryConnector.startsWith("blend_")) {
        body = generateBlendRequestBody({
          dates: formattedDates,
          compareDates,
          appliedMetricsSorts,
          appliedDimsSorts,
          selectedAccounts,
          selectedDimsOptions,
          selectedDimsColsOptions,
          selectedMetricsOptions,
          limits,
          appliedFilters,
          dynamicFormValues,
          selectedSpecialSettings,
          selectedConnectors,
          entryConnector,
          config,
          token,
          userName,
        });
        await checkAvailableQueries(token, "blend", "create", body);
      } else {
        body = generateDefaultRequestBody({
          dates: formattedDates,
          compareDates,
          appliedMetricsSorts,
          appliedDimsSorts,
          selectedAccounts,
          selectedDimsOptions,
          selectedDimsColsOptions,
          selectedMetricsOptions,
          limits,
          appliedFilters,
          dynamicFormValues,
          selectedSpecialSettings,
          selectedConnectors,
          entryConnector,
          config,
          selectedReportOption,
        });
        await checkAvailableQueries(token, "normal", "create", body);
      }
      setRequestBody(body);
      setDialogOpen(true);
    } catch (error) {
      setDialogOpen(false);
    }
  }, [
    // generateTableMutation,
    entryConnector,
    dates,
    appliedMetricsSorts,
    appliedDimsSorts,
    selectedAccounts,
    selectedDimsOptions,
    selectedDimsColsOptions,
    selectedMetricsOptions,
    limits,
    appliedFilters,
    dynamicFormValues,
    selectedSpecialSettings,
    selectedConnectors,
    config,
    token,
    userName,
    queries,
  ]);

  React.useEffect(() => {
    setSelectedQuery(getSelectedQuery());
    if (!getSelectedQuery()) {
      reinitializeValues();
    }
  }, [getSelectedQuery()]);

  const reinitializeValues = () => {
    setSelectedAccounts([]);
    setSelectedMetricsOptions([]);
    setSelectedDimsOptions([]);
    setSelectedDimsColsOptions([]);
    setAppliedFilters([]);
    setAppliedMetricsSorts([]);
    setAppliedDimsSorts([]);
    setLimits({
      limitRows: 0,
      limitCols: 0,
    });
    setSelectedSpecialSettings({});
    setSelectedReportOption(null);
    setDynamicFormValues({});
  };

  const confirmConnection = (connectorsRef, query, data) => {
    if (connectorsRef && query && data) {
      connectorsRef.confirmConnection(
        query.dataSource,
        data.datasources[query.dataSource].name,
        data.datasources[query.dataSource],
        typeof query.refreshWithUserAccount === "string"
          ? JSON.parse(query.refreshWithUserAccount)
          : query.refreshWithUserAccount
      );
    }
  };

  const applySelections = (
    selectedQuery,
    setSelectedAccounts,
    setSelectedMetricsOptions,
    setSelectedDimsOptions,
    setSelectedDimsColsOptions,
    setAppliedFilters,
    setAppliedMetricsSorts,
    setAppliedDimsSorts,
    setLimits,
    setSelectedSpecialSettings,
    setDynamicFormValues,
    setSelectedReportOption,
    setPreviousReportType,
    setDates,
    setSelectedConnectors
  ) => {
    if (selectedQuery) {
      const parseIfString = (value) => {
        try {
          return typeof value === "string" ? JSON.parse(value) : value;
        } catch (e) {
          return value;
        }
      };

      setSelectedAccounts(parseIfString(selectedQuery.accountsViews));
      setSelectedMetricsOptions(parseIfString(selectedQuery.metrics));
      setSelectedDimsOptions(parseIfString(selectedQuery.dimensions) || []);
      setSelectedDimsColsOptions(parseIfString(selectedQuery.pivotDimensions) || []);
      setAppliedFilters(parseIfString(selectedQuery.filters));
      setSelectedSpecialSettings(parseIfString(selectedQuery.specialSettings));
      setDates({
        start_date: selectedQuery.startDate,
        end_date: selectedQuery.endDate,
        date_range_type: selectedQuery.dateRangeType,
      });
      setAppliedMetricsSorts(
        selectedQuery.sort ? parseIfString(selectedQuery.sort).map((sort) => ({ metric: sort[0], order: sort[1] })) : []
      );

      setAppliedDimsSorts(
        selectedQuery.sortDimensions
          ? parseIfString(selectedQuery.sortDimensions).map((sort) => ({ dim: sort[0], order: sort[1] }))
          : []
      );

      setLimits({
        limitRows: selectedQuery.maxRows || 0,
        limitCols: selectedQuery.maxPivotCategories || 0,
      });

      setDynamicFormValues({
        ...(selectedQuery.otherParameters ? parseIfString(selectedQuery.otherParameters) : {}),
        ...(selectedQuery.accountsViews ? parseIfString(selectedQuery.accountsViews)[0] : {}),
      });

      setSelectedReportOption(selectedQuery.reportType);
      setPreviousReportType(selectedQuery.reportType);
      setSelectedConnectors(
        selectedQuery.refreshWithUserAccount
          ? { [selectedQuery.dataSource]: parseIfString(selectedQuery.refreshWithUserAccount) }
          : {}
      );
    }
  };

  const waitForRefAndInitialize = React.useCallback(() => {
    const tryInitialize = () => {
      if (connectorsRef.current && selectedQuery) {
        confirmConnection(connectorsRef.current, selectedQuery, data);
        applySelections(
          selectedQuery,
          setSelectedAccounts,
          setSelectedMetricsOptions,
          setSelectedDimsOptions,
          setSelectedDimsColsOptions,
          setAppliedFilters,
          setAppliedMetricsSorts,
          setAppliedDimsSorts,
          setLimits,
          setSelectedSpecialSettings,
          setDynamicFormValues,
          setSelectedReportOption,
          setPreviousReportType,
          setDates,
          setSelectedConnectors
        );
      } else if (!selectedQuery) {
        reinitializeValues();
      } else {
        window.requestAnimationFrame(tryInitialize);
      }
      setIsEditing(false);
    };

    tryInitialize();
  }, [selectedQuery, data, connectorsRef]);

  // React.useEffect(() => {
  //   if (selectedQuery) {
  //     waitForRefAndInitialize();
  //   }
  // }, [selectedQuery, waitForRefAndInitialize]);

  const initializeEditing = React.useCallback(() => {
    if (selectedQuery) {
      setExpandedItem("connectors");
      waitForRefAndInitialize();
    }
  }, [selectedQuery, waitForRefAndInitialize]);

  React.useEffect(() => {
    if (isEditing) initializeEditing();
  }, [initializeEditing]);

  const { data: tableData, refetch: tableDataRefetch } = useQuery(
    ["accountsByTaskId", tableTaskId],
    async () => {
      if (tableTaskId) {
        const response = await getAccountsByTaskId({ taskId: tableTaskId, signal: abortController.current.signal });
        if (response?.ok) {
          const result = await response.json();
          return result;
        } else {
          throw new Error("Error fetching accounts");
        }
      }
    },
    {
      enabled: !!tableTaskId,
      onSuccess: (data) => {
        if (data?.ok && data?.finished && !hasWrittenData) {
          const endTime = performance.now();
          const executionTime = ((endTime - startTimeRef.current) / 1000).toFixed(2);
          const queryUUID = generateUUID();
          const requestBodyExcel = buildQueryRequest(
            selectedQuery,
            entryConnector,
            connectedConnectorName,
            requestBody,
            dates,
            selectedAccounts,
            selectedMetricsOptions,
            dynamicFormValues,
            selectedConnectors,
            selectedDimsOptions,
            selectedDimsColsOptions,
            limits
          );
          writeDataToExcel(
            data.result,
            "A1",
            isDuplicating || !selectedQuery ? queryUUID : requestBodyExcel.queryUUID,
            requestBodyExcel,
            isDuplicating || !selectedQuery ? entryConnector : requestBodyExcel.dataSource,
            executionTime,
            isDuplicating || !selectedQuery ? addQuery : updateQuery,
            isDuplicating || !selectedQuery ? false : true,
            showDialog
          ).then(() => {
            setHasWrittenData(true);
            setSelectedQueryId(isDuplicating || !selectedQuery ? queryUUID : requestBodyExcel.queryUUID);
            setIsDuplicating(false);
          });
          setDialogOpen(false);
          setTableTaskId(null);
          refetchUserInfo();
        } else if (!data?.ok && data?.finished) {
          showDialog(data.title_error, data.error);
          setTableTaskId(null);
          setDialogOpen(false);
        } else if (data?.ok && !data?.finished && data?.state === "not_in_queue") {
          setDialogOpen(false);
          setTableTaskId(null);
          showDialog("Error message", ["The accounts could not be retrieved. Please try again later"]);
        } else if (!data?.finished) {
          // setDialogOpen(true);
          setTimeout(() => {
            tableDataRefetch();
          }, 3000);
        }
      },
      onError: (error) => {
        abortController.current = new AbortController();
        setTableTaskId(null);
        setDialogOpen(false);
      },
    }
  );

  const handleCancelTask = async (taskId, abortController) => {
    try {
      abortController.abort();
      const response = await deleteTaskId({ taskId });
      if (response?.ok) {
        setDialogOpen(false);
        // setTableTaskId(null);
      } else {
        throw new Error("Error deleting task");
      }
    } catch (error) {
      console.error("Error cancelling the task:", error);
    }
  };

  const handleCancelTaskClick = () => {
    if (tableTaskId) {
      handleCancelTask(tableTaskId, abortController.current);
    }
  };

  React.useEffect(() => {
    if (tableTaskId && !hasWrittenData) {
      startTimeRef.current = performance.now();
      tableDataRefetch();
    }
  }, [tableTaskId, hasWrittenData]);

  return (
    <>
      <RunningDialog
        dialogOpen={dialogOpen}
        taskId={tableTaskId}
        handleCancelTaskClick={handleCancelTaskClick}
        setDialogOpen={setDialogOpen}
      />
      <div className={styles.root}>
        <div className={styles.generateTable}>
          <div id="back-to-queries">
            <Button
              className={styles.padding0}
              appearance="transparent"
              onClick={() => {
                setSelectedQueryId(null);
                navigate("/");
              }}
              icon={<ArrowLeft16Filled />}
            >
              Manage Queries
            </Button>
          </div>
          {(!selectedQuery || isDuplicating) && (
            <div id="generate-table-button" className="my-4">
              <Button
                disabled={!stablishedConnection}
                onClick={handleGenerateTable}
                className="w-full"
                appearance="primary"
              >
                Generate table
              </Button>
            </div>
          )}
          {selectedQuery && !isDuplicating && (
            <div id="generate-table-button" className="my-4">
              <div className="flex gap-5">
                <Button onClick={handleGenerateTable} className="w-full" appearance="primary">
                  Confirm changes
                </Button>
                <Button
                  className="w-full"
                  appearance="secondary"
                  onClick={() => {
                    setSelectedQueryId(null);
                    navigate("/");
                  }}
                >
                  Cancel
                </Button>
              </div>
              <p className={styles.modify}>
                Modifying query at {selectedQuery?.sheetName || ""} in range {selectedQuery?.rangeAddress || ""}
              </p>
            </div>
          )}
        </div>
        <div id="add-query">
          <Accordion
            collapsible
            openItems={expandedItem}
            onToggle={(e, data) => {
              setExpandedItem(data.openItems);
            }}
          >
            <AccordionItem key="connectors" value="connectors">
              <AccordionHeader
                expandIconPosition="end"
                className={styles.accordionHeader}
                icon={<LinkMultipleFilled />}
              >
                <span>
                  CONNECTORS{" "}
                  <Caption2>
                    {stablishedConnection && connectedConnectorName && ` - ${connectedConnectorName}`}
                  </Caption2>
                </span>
              </AccordionHeader>
              <AccordionPanel className={styles.marginPanel}>
                <ConnectorsAccordion
                  datasources={data || []}
                  otherSources={loginInfo || []}
                  onConnectionChange={handleConnectionChange}
                  onTaskIdChange={setTaskId}
                  selectedQuery={selectedQuery}
                  ref={connectorsRef}
                  selectedConnectors={selectedConnectors}
                  onChangeConnectors={setSelectedConnectors}
                  infoConnector={infoConnector}
                  setInfoConnector={setInfoConnector}
                  setConfiguration={setConfiguration}
                  setStablishedConnection={setStablishedConnection}
                  onAccountsChange={setSelectedAccounts}
                  setExpandedItem={setExpandedItem}
                  setLoginInfo={setLoginInfo}
                  getConnectorsRefetch={getConnectorsRefetch}
                />
              </AccordionPanel>
            </AccordionItem>
            {connectorConfigurations[entryConnector] &&
              configuration &&
              configuration.accordions?.reportConfiguration && (
                <AccordionItem value="reportConfiguration">
                  <AccordionHeader
                    expandIconPosition="end"
                    className={styles.accordionHeader}
                    icon={<SettingsFilled />}
                  >
                    REPORT CONFIGURATION
                  </AccordionHeader>
                  <AccordionPanel className={styles.marginPanel}>
                    <ReportConfigAccordion
                      setSelectedReportOption={setSelectedReportOption}
                      reportOptions={reportOptions}
                      sections={configuration.accordions.reportConfiguration.sections}
                      setDynamicFormValues={setDynamicFormValues}
                      dynamicFormValues={dynamicFormValues}
                      setSelectedAccounts={setSelectedAccounts}
                    />
                  </AccordionPanel>
                </AccordionItem>
              )}
            {stablishedConnection &&
              (connectorsWithAccounts.includes(entryConnector) || entryConnector.startsWith("blend_")) && (
                <>
                  <AccordionItem value="2">
                    <AccordionHeader
                      expandIconPosition="end"
                      className={styles.accordionHeader}
                      icon={<PersonCircleFilled />}
                    >
                      YOUR ACCOUNTS
                      <span className="accountText">
                        {selectedAccounts && selectedAccounts.map((account) => account.name).join(", ")}
                      </span>
                    </AccordionHeader>
                    <AccordionPanel className={styles.accountsPanel}>
                      <AccountsList
                        setMetrics={setAdditionalMetrics}
                        setDims={setAdditionalDimensions}
                        accounts={processedAccounts}
                        selectedAccounts={selectedAccounts}
                        onAccountsChange={setSelectedAccounts}
                        isBlend={entryConnector.startsWith("blend_")}
                        connector={entryConnector}
                        infoConnector={infoConnector}
                      />
                    </AccordionPanel>
                  </AccordionItem>
                </>
              )}
            <AccordionItem value="3">
              <AccordionHeader expandIconPosition="end" className={styles.accordionHeader} icon={<TableFilled />}>
                DATA TABLE
              </AccordionHeader>
              <AccordionPanel className="min-h-80">
                {!fallbackConfig ? (
                  <DataTableAccordion
                    datasource={entryConnector}
                    filters={filters}
                    dimensions={dimensions}
                    sourceMetrics={metrics}
                    selectedDimsOptions={selectedDimsOptions || []}
                    selectedDimsColsOptions={selectedDimsColsOptions || []}
                    selectedMetricsOptions={selectedMetricsOptions || []}
                    appliedFilters={appliedFilters || []}
                    appliedMetricsSorts={appliedMetricsSorts || []}
                    appliedDimsSorts={appliedDimsSorts || []}
                    setSelectedDimsOptions={setSelectedDimsOptions}
                    setSelectedDimsColsOptions={setSelectedDimsColsOptions}
                    setSelectedMetricsOptions={setSelectedMetricsOptions}
                    setAppliedFilters={setAppliedFilters}
                    setAppliedMetricsSorts={setAppliedMetricsSorts}
                    setAppliedDimsSorts={setAppliedDimsSorts}
                    onDatesChange={setDates}
                    onCompareDatesChange={setCompareDates}
                    dates={dates}
                    setDates={setDates}
                    limits={limits}
                    setLimits={setLimits}
                    reportType={selectedReportOption}
                    setPreviousReportType={setPreviousReportType}
                    previousReportType={previousReportType}
                  />
                ) : fallbackConfig && fallbackConfig.dataTable?.sections?.length ? (
                  <DynamicForm
                    sections={fallbackConfig?.dataTable?.sections}
                    onValuesChange={setDynamicFormValues}
                    dynamicFormValues={dynamicFormValues}
                    database={processedAccounts}
                  />
                ) : (
                  <div>No data available for this connector.</div>
                )}
              </AccordionPanel>
            </AccordionItem>
            {stablishedConnection && (allMetrics?.options?.length > 0 || configuration?.accordions?.options) && (
              <AccordionItem value="4">
                <AccordionHeader
                  expandIconPosition="end"
                  className={styles.accordionHeader}
                  icon={<TextBulletListSquareFilled />}
                >
                  <span>
                    OPTIONS{" "}
                    <Caption2>
                      {selectedSpecialSettings &&
                        Object.keys(selectedSpecialSettings).length > 0 &&
                        `${Object.keys(selectedSpecialSettings).length} selected`}
                    </Caption2>
                  </span>
                  <Button
                    as="a"
                    href="https://support.dataslayer.ai/dataslayerai-advanced-options"
                    className={styles.infoButton}
                    appearance="transparent"
                    icon={<QuestionCircleFilled />}
                  />
                </AccordionHeader>
                <AccordionPanel>
                  {allMetrics?.options?.length > 0 && (
                    <OptionsAccordion
                      options={allMetrics.options}
                      onOptionsChange={setSelectedSpecialSettings}
                      selectedOptions={selectedSpecialSettings}
                    />
                  )}
                </AccordionPanel>
              </AccordionItem>
            )}
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default MainAccordion;
