import { useEffect } from "react";
import { getAllSchedules, updateSchedule } from "../services/actions";
import { getFileWebUrlById, getOrSaveOneDriveFileId } from "./office-apis-helpers";
import { config } from "../Constants";

const useRefreshTokenWatcher = () => {
  useEffect(() => {
    const checkRefreshToken = async () => {
      const storedToken = localStorage.getItem("DS-EXCEL-REFRESH-TOKEN");
      const userName = localStorage.getItem("DS-EXCEL-USERNAME") || null;
      if (!storedToken || !userName) {
        sessionStorage.removeItem("previous_refresh_token");
        return;
      }
      const previousToken = sessionStorage.getItem("previous_refresh_token");

      if (storedToken !== previousToken) {
        try {
          const context = new Excel.RequestContext();
          const storedFileId = await getOrSaveOneDriveFileId(context);

          if (!storedFileId) {
            return;
          }

          const webUrl = await getFileWebUrlById(localStorage.getItem("DS-EXCEL-TOKEN"), storedFileId);

          const response = await getAllSchedules({
            user_email: userName,
            service_id: config.SERVICE_ID,
            document_url: webUrl,
          });

          if (response?.ok) {
            const schedules = await response.json();
            if (schedules?.data.schedules.length > 0) {
              //Actualizar los schedules con el nuevo refresh token
              await Promise.all(
                schedules.data.schedules.map((schedule) => {
                  const updatedSchedule = {
                    ...schedule,
                    metadata_data: {
                      ...schedule.metadata_data,
                      refresh_token: storedToken,
                    },
                  };
                  return updateSchedule(userName, updatedSchedule);
                })
              );
            }
          } else {
            throw new Error("Error getting schedules.");
          }
          sessionStorage.setItem("previous_refresh_token", storedToken);
        } catch (error) {
          throw new Error("Error updating schedules");
        }
      }
    };

    // Detectar cambios en localStorage
    const handleStorageChange = (event) => {
      if (event.key === "DS-EXCEL-REFRESH-TOKEN") {
        checkRefreshToken();
      }
    };

    window.addEventListener("storage", handleStorageChange);
    checkRefreshToken();

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
};

export default useRefreshTokenWatcher;
