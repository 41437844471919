import React, { useState } from "react";
import {
  TagPicker,
  TagPickerList,
  TagPickerInput,
  TagPickerControl,
  TagPickerGroup,
  TagPickerOption,
  TagPickerOptionGroup,
  makeStyles,
  Button,
  Label,
} from "@fluentui/react-components";
import { Tag, Field } from "@fluentui/react-components";
import { DeleteFilled } from "@fluentui/react-icons";

const useStyles = makeStyles({
  tagPickerWrapper: {
    position: "relative",
    height: "300px",
  },
  tagPickerList: {
    position: "absolute",
    top: "60px",
    width: "100%",
    zIndex: 1,
    backgroundColor: "white",
    border: "1px solid #ccc",
    borderRadius: "4px",
    maxHeight: "240px",
    overflowY: "auto",
  },
  accountGroupName: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    fontWeight: "bold!important",
    fontStyle: "italic",
    color: "#b521cf",
  },
  label: {
    color: "#b521cf",
    fontWeight: "bold",
    fontStyle: "italic",
  },
  groupName: {
    fontWeight: "bold!important",
    textTransform: "uppercase",
  },
});

const AccountsList = ({ accounts, onAccountsChange, selectedAccounts, isBlend, connector, infoConnector }) => {
  const styles = useStyles();
  const [filter, setFilter] = useState("");
  const normalizedData = normalizeAccountsData(accounts);
  const getIdentifier = (account) => {
    if (connector === "dbm" || connector === "dcm") {
      return account.advertiser_id;
    }
    if (connector === "bigquery") {
      return account.name;
    }
    return account.id;
  };

  const onInputChange = (e) => {
    setFilter(e.target.value.toLowerCase());
  };

  const filteredAccounts = () => {
    if (!filter) return normalizedData;
    const filtered = {};
    Object.keys(normalizedData).forEach((email) => {
      filtered[email] = {};
      Object.keys(normalizedData[email]).forEach((group) => {
        filtered[email][group] = normalizedData[email][group].filter(
          (account) => account.name && account.name.toString().toLowerCase().includes(filter)
        );
      });
    });
    return filtered;
  };

  const filteredData = filteredAccounts();

  const handleSelectAll = (groupKey) => {
    const allOptions = [];

    if (Array.isArray(accounts[groupKey])) {
      accounts[groupKey].forEach((account) => {
        allOptions.push({ ...account });
        // allOptions.push({ ...account, connection_id: groupKey, email: groupKey });
      });
    } else {
      Object.keys(accounts[groupKey]).forEach((accountGroup) => {
        const group = accounts[groupKey][accountGroup];
        if (Array.isArray(group)) {
          group.forEach((account) => {
            allOptions.push({ ...account });
            // allOptions.push({ ...account, connection_id: groupKey, email: groupKey });
          });
        }
      });
    }

    onAccountsChange((prevSelectedOptions) => getUniqueOptions(prevSelectedOptions, allOptions));
  };

  const handleSelectAllSubGroup = (groupKey, subGroupKey) => {
    const allOptions = filteredData[groupKey][subGroupKey].map((account) => ({
      ...account,
      // connection_id: groupKey,
      // email: groupKey,
    }));

    onAccountsChange((prevSelectedOptions) => getUniqueOptions(prevSelectedOptions, allOptions));
  };

  const getUniqueOptions = (prevOptions, newOptions) => {
    const combinedOptions = [...prevOptions, ...newOptions];
    return combinedOptions.reduce((acc, current) => {
      const x = acc.find((item) => getIdentifier(item) === getIdentifier(current));
      if (!x) {
        acc.push(current);
      }
      return acc;
    }, []);
  };

  // Función para normalizar las cuentas
  function normalizeAccountsData(accountsData) {
    const normalized = {};
    Object.keys(accountsData).forEach((email) => {
      const accountGroup = accountsData[email];
      if (Array.isArray(accountGroup)) {
        normalized[email] = { default: accountGroup };
      } else if (typeof accountGroup === "object") {
        normalized[email] = accountGroup;
      }
    });

    return normalized;
  }
  const isSelected = (identifier) => selectedAccounts.some((account) => getIdentifier(account) === identifier);

  const handleOptionSelect = (e, data) => {
    const selectedFullOptions = data.selectedOptions.map((selectedId) => {
      return Object.values(normalizedData)
        .flatMap((group) => Object.values(group).flat())
        .find((account) => getIdentifier(account) === selectedId);
    });

    if (infoConnector.has_all_accounts) {
      onAccountsChange(selectedFullOptions);
    } else {
      const lastSelectedOption = selectedFullOptions.slice(-1);
      onAccountsChange(lastSelectedOption);
    }
    setFilter(null);
  };
  return (
    <Field style={{ maxWidth: 400 }}>
      <div className="flex justify-between items-center">
        <span className={styles.label}>You can select unlimited accounts</span>
        <Button
          onClick={() => {
            onAccountsChange([]);
          }}
          appearance="transparent"
          icon={<DeleteFilled />}
        />
      </div>
      <div className={styles.tagPickerWrapper}>
        <TagPicker
          inline={true}
          positioning={{ position: "below", align: "bottom", pinned: true, disableUpdateOnResize: true }}
          open={true}
          onOptionSelect={handleOptionSelect}
          selectedOptions={selectedAccounts.map((option) => getIdentifier(option))}
        >
          <TagPickerControl>
            <TagPickerGroup>
              {selectedAccounts.map((option) => (
                <Tag key={getIdentifier(option)} shape="rounded" value={getIdentifier(option)}>
                  {option.name}
                </Tag>
              ))}
            </TagPickerGroup>
            <TagPickerInput
              aria-label="Select Accounts"
              onChange={onInputChange}
              onBlur={(e) => {
                e.preventDefault;
                setFilter(null);
              }}
            />
          </TagPickerControl>
          <TagPickerList>
            {Object.keys(filteredData).map((groupKey) => (
              <div key={groupKey} className="flex flex-col">
                {!isBlend && (
                  <>
                    {infoConnector.has_all_accounts ? (
                      <Button appearance="transparent" onClick={() => handleSelectAll(groupKey)}>
                        Select all {groupKey}
                      </Button>
                    ) : (
                      <Label className={styles.label}>{groupKey}</Label>
                    )}
                  </>
                )}
                {Object.keys(filteredData[groupKey]).map((subGroupKey) => (
                  <React.Fragment key={subGroupKey}>
                    <Label className={styles.groupName} onClick={() => handleSelectAllSubGroup(groupKey, subGroupKey)}>
                      {subGroupKey}
                    </Label>
                    <TagPickerOptionGroup>
                      {filteredData[groupKey][subGroupKey]
                        .filter((account) => !isSelected(getIdentifier(account)))
                        .map((account) => (
                          <TagPickerOption
                            value={getIdentifier(account)}
                            key={getIdentifier(account)}
                            text={account.name.toString()}
                          >
                            {account.name}
                          </TagPickerOption>
                        ))}
                    </TagPickerOptionGroup>
                  </React.Fragment>
                ))}
              </div>
            ))}
          </TagPickerList>
        </TagPicker>
      </div>
    </Field>
  );
};

export default AccountsList;
