import * as React from "react";
import CryptoJS from "crypto-js";
import {
  Accordion,
  useId,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Input,
  makeStyles,
  typographyStyles,
  Image,
  Button,
  Checkbox,
  Text,
} from "@fluentui/react-components";
import { SearchFilled } from "@fluentui/react-icons";

import { getAccountByDataSource, getAccountsByBlend, getDataTables } from "../../services/connector";
import { config } from "../../Constants";
import { useDialog } from "../context/DialogContext";
import { useMutation } from "react-query";
import RunningDialog from "./RunningDialog";
import { connectorConfigurations, specialConnectors } from "../../utilities/connectorsConfiguration";
import { showDialog } from "../../utilities/excel";
import { useQueryContext } from "../context/QueryContext";

const useStyles = makeStyles({
  searchInput: { display: "flex", flexDirection: "column", gap: "2px", margin: "20px 10px" },
  logoImg: { marginRight: "7px", padding: "5px" },
  connectorTitle: typographyStyles.body1,
  manageAccounts: { display: "flex", flexDirection: "column", gap: "2px", margin: "20px 10px" },
});
const DATASLAYER_URL = config.DATASLAYER_URL;

const encrypt = (data, passphrase, expiration = 60) => {
  const payload = [Math.round(new Date().getTime() / 1000) + expiration, data];
  const salt = CryptoJS.lib.WordArray.random(6);
  const iv = CryptoJS.lib.WordArray.random(16);
  const key = CryptoJS.PBKDF2(passphrase, salt, {
    hasher: CryptoJS.algo.SHA512,
    keySize: 64 / 8,
    iterations: 999,
  });
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(payload), key, {
    iv,
  });
  return {
    ciphertext: encodeURIComponent(CryptoJS.enc.Base64.stringify(encrypted.ciphertext)),
    salt: encodeURIComponent(CryptoJS.enc.Hex.stringify(salt)),
    iv: encodeURIComponent(CryptoJS.enc.Hex.stringify(iv)),
  };
};

const ConnectorsAccordion = React.forwardRef((props, ref) => {
  const styles = useStyles();
  const {
    datasources,
    otherSources,
    onConnectionChange,
    onTaskIdChange,
    onChangeConnectors,
    selectedConnectors,
    setInfoConnector,
    setConfiguration,
    setStablishedConnection,
    setExpandedItem,
    setLoginInfo,
    getConnectorsRefetch,
  } = props;
  const token = localStorage.getItem("DS-TOKEN");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedConnections, setSelectedConnections] = React.useState(selectedConnectors || {});
  const [openItems, setOpenItems] = React.useState([]);
  const { dialogOpen, setDialogOpen } = useDialog();
  const beforeId = useId("content-before");
  const { setSelectedQueryId } = useQueryContext();
  const mergeDatasourcesAndBlends = (datasources, blends) => {
    Object.keys(blends).forEach((blendKey) => {
      datasources[blendKey] = blends[blendKey];
    });

    return datasources;
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  let dataSourcesEntries = [];

  if (datasources && datasources.datasources) {
    const mergedEntries = mergeDatasourcesAndBlends(datasources.datasources, otherSources.blends || {});
    dataSourcesEntries = Object.entries(mergedEntries).sort((a, b) => {
      const nameA = a[1].name.toLowerCase();
      const nameB = b[1].name.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  }

  const filteredEntries = dataSourcesEntries.filter(([key, value]) =>
    value.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCheckboxChange = (conectorKey, connection) => {
    setSelectedConnections((prevSelected) => {
      const connectorSelections = prevSelected[conectorKey] || [];
      const isSelected = connectorSelections.some((conn) => conn.id === connection.id);

      if (isSelected) {
        return {
          ...prevSelected,
          [conectorKey]: connectorSelections.filter((conn) => conn.id !== connection.id),
        };
      } else {
        return {
          ...prevSelected,
          [conectorKey]: [...connectorSelections, connection],
        };
      }
    });

    onChangeConnectors((prevSelected) => {
      const connectorSelections = prevSelected[conectorKey] || [];
      const isSelected = connectorSelections.some((conn) => conn.id === connection.id);

      if (isSelected) {
        return {
          [conectorKey]: connectorSelections.filter((conn) => conn.id !== connection.id),
        };
      } else {
        return {
          [conectorKey]: [...connectorSelections, connection],
        };
      }
    });
  };

  const mutation = useMutation(
    async ({ connectorKey, connectionsToSubmit, connectorName }) => {
      const response = await getAccountByDataSource({
        token,
        datasource: connectorKey,
        connections: connectionsToSubmit,
      });
      if (response.ok) {
        return await response.json();
      }
    },
    {
      onSuccess: (res, { connectorKey, connectorName }) => {
        if (res.ok) {
          onTaskIdChange(res.task_id);
          onConnectionChange(connectorName, connectorKey);
        } else {
          showDialog(res.title_error, res.error);
          setDialogOpen(false);
        }
      },
      onError: (error) => {
        setDialogOpen(false);
        showDialog("Error", ["Error confirming connection to hub account"]);
      },
    }
  );

  const dataBaseMutation = useMutation(
    async ({ connectionsToSubmit, connectorName, conectorKey }) => {
      const response = await getDataTables({
        token,
        connections: connectionsToSubmit,
      });
      if (response.ok) return await response.json();
      throw new Error("Error confirming database connection");
    },
    {
      onSuccess: (res, { connectorKey, connectorName }) => {
        onTaskIdChange(res.task_id);
        onConnectionChange(connectorName, connectorKey);
      },
      onError: (error) => {
        setDialogOpen(false);
      },
    }
  );

  const confirmConnection = (connectorKey, connectorName, source, isEdit) => {
    setDialogOpen(true);
    const isBlendConnector = connectorKey.startsWith("blend_");
    const isSpecialConnector = specialConnectors.find((connector) => connector.key === connectorKey) || false;
    const connectionsToSubmit = isEdit || selectedConnections[connectorKey];
    // const connectionsToSubmit = selectedConnections[connectorKey] || [];
    if (!isEdit) setSelectedQueryId(null);
    setInfoConnector(source);
    if (isSpecialConnector) {
      if (source?.hub_account_type === "unique") {
        if (connectionsToSubmit.length > 1) {
          showDialog("Select only one hub account", ["You must select only one hub account to continue"]);
          setDialogOpen(false);
          return;
        }
        if (connectionsToSubmit.length === 0) {
          showDialog("Select an account", ["You must select a hub account to continue"]);
          setDialogOpen(false);
          return;
        }
        if (connectorKey === "database") {
          dataBaseMutation.mutate({ connectionsToSubmit, connectorName, connectorKey });
          evaluateConnectorConfiguration(connectorKey);
          setStablishedConnection(true);
          onConnectionChange(connectorName, connectorKey);
          setSelectedConnections(connectionsToSubmit);
          setDialogOpen(false);
        } else if (connectorKey === "bigquery") {
          evaluateConnectorConfiguration(connectorKey);
          mutation.mutate({ connectorKey, connectionsToSubmit, connectorName });
        } else {
          evaluateConnectorConfiguration(connectorKey);
          setStablishedConnection(true);
          onConnectionChange(connectorName, connectorKey);
          setSelectedConnections(connectionsToSubmit);
          onChangeConnectors({ [connectorKey]: connectionsToSubmit });
          setDialogOpen(false);
        }
      } else {
        evaluateConnectorConfiguration(connectorKey);
        setStablishedConnection(true);
        onConnectionChange(connectorName, connectorKey);
        onChangeConnectors({});
        setDialogOpen(false);
      }
    } else if (isBlendConnector) {
      blendAccountsMutation.mutate({ connectionsToSubmit: source.connections, connectorName, connectorKey });
      onChangeConnectors({ [connectorKey]: source.connections });
    } else {
      if (connectionsToSubmit.length === 0) {
        setDialogOpen(false);
        showDialog("Select hub account", ["You must select at least one hub account to continue"]);
        return;
      }
      mutation.mutate({ connectorKey, connectionsToSubmit, connectorName });
    }
    setExpandedItem(null);
  };

  const evaluateConnectorConfiguration = (key) => {
    const config = connectorConfigurations[key];

    if (config) {
      const connectorConfig = config;
      setConfiguration(connectorConfig);
    }
  };

  const blendAccountsMutation = useMutation(
    async ({ connectionsToSubmit, connectorName, connectorKey }) => {
      const response = await getAccountsByBlend({
        token,
        hash_id: connectorKey,
        service_id: config.SERVICE_ID,
        connections: connectionsToSubmit,
      });
      if (response.ok) return await response.json();
      throw new Error("Error fetching Blend accounts");
    },
    {
      onSuccess: (res, { connectorKey, connectorName }) => {
        if (res.ok) {
          onTaskIdChange(res.task_id);
          onConnectionChange(connectorName, connectorKey);
        } else {
          showDialog(res.title_error, res.error);
        }
        setDialogOpen(false);
      },
      onError: (error) => {
        setDialogOpen(false);
      },
    }
  );

  const handleManageHubAccounts = (key) => {
    const activeEmail = localStorage.getItem("DS-EXCEL-USERNAME");
    const hubHash = process.env.REACT_APP_HUB_HASH;
    const expiration = 20 * 60;
    const encryptedHubData = encrypt(activeEmail, hubHash, expiration);
    const linkUrl = `${DATASLAYER_URL}/hub/${key}?hash=${encryptedHubData.ciphertext}&iv=${encryptedHubData.iv}&salt=${encryptedHubData.salt}&full=true`;
    const authTab = window.open(linkUrl, "_blank");
    const checkLoggin = setInterval(() => {
      if (!authTab || authTab.closed) {
        clearInterval(checkLoggin);
        setLoginInfo();
        getConnectorsRefetch();
      }
    }, 100);
  };

  React.useImperativeHandle(ref, () => ({
    confirmConnection,
    handleCheckboxChange,
  }));

  const handleToggle = (event, data) => {
    setOpenItems(data.openItems);
  };

  React.useEffect(() => {
    const newOpenItems = filteredEntries.filter(([key]) => selectedConnections[key]?.length > 0).map(([key]) => key);
    setOpenItems((prevOpenItems) => {
      const areItemsEqual =
        new Set(prevOpenItems).size === new Set(newOpenItems).size &&
        prevOpenItems.every((item) => newOpenItems.includes(item));

      if (areItemsEqual) {
        return prevOpenItems;
      }

      return [...new Set([...prevOpenItems, ...newOpenItems])];
    });
  }, [selectedConnections]);

  return (
    <div>
      <RunningDialog dialogOpen={dialogOpen} />
      <Accordion collapsible openItems={openItems} onToggle={handleToggle} multiple>
        <div className={styles.searchInput}>
          <Input
            placeholder="Search connectors"
            contentBefore={<SearchFilled />}
            id={beforeId}
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        {filteredEntries && filteredEntries.length > 0 ? (
          filteredEntries.flatMap(([key, source], index) => (
            <AccordionItem key={key} value={key}>
              <AccordionHeader
                onClick={() => {
                  if (source?.hub_account_type === null) {
                    confirmConnection(key, source.name, source);
                  }
                }}
                expandIconPosition="end"
              >
                <Image
                  className={styles.logoImg}
                  alt={`logo connector ${index + 1}`}
                  src={
                    key.startsWith("blend_")
                      ? "https://dataslayer-logos-eur.s3.eu-west-1.amazonaws.com/add-on/blend-dark.png"
                      : `https://dataslayer-logos-eur.s3.eu-west-1.amazonaws.com/add-on/${key}.png`
                  }
                  height={25}
                  width={25}
                />
                <span className={styles.connectorTitle}>{source.name}</span>
              </AccordionHeader>
              <AccordionPanel>
                <div className="flex flex-col gap-2 mb-4 overflow-hidden">
                  {source.connections.map((connection, index) => (
                    <div key={index}>
                      {key.startsWith("blend_") ? (
                        <Text className="p-2" variant="medium">
                          {connection.id}
                        </Text>
                      ) : (
                        <Checkbox
                          label={connection.id}
                          value={connection.id}
                          checked={selectedConnections[key]?.some((conn) => conn.id === connection.id) || false}
                          onChange={() => handleCheckboxChange(key, connection)}
                        />
                      )}
                    </div>
                  ))}

                  {source.hub_account_type !== null && (
                    <div className="flex justify-around">
                      {!key.startsWith("blend_") ? (
                        <Button onClick={() => handleManageHubAccounts(key)} target="_blank">
                          Manage Hub Accounts
                        </Button>
                      ) : (
                        <div style={{ flexGrow: 1 }} />
                      )}
                      <Button
                        appearance="primary"
                        onClick={() => {
                          confirmConnection(key, source.name, source);
                        }}
                      >
                        Confirm
                      </Button>
                    </div>
                  )}
                </div>
              </AccordionPanel>
            </AccordionItem>
          ))
        ) : (
          <p>No data available</p>
        )}
      </Accordion>
    </div>
  );
});

ConnectorsAccordion.displayName = "ConnectorsAccordion";

export default ConnectorsAccordion;
