import React, { createContext, useContext, useState, useEffect } from "react";

const QueryContext = createContext();

export const useQueryContext = () => useContext(QueryContext);

export const QueryProvider = ({ children }) => {
  const [queryParams, setQueryParams] = useState({});
  const [queries, setQueries] = useState([]);
  const [selectedQueryId, setSelectedQueryId] = useState(null);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [progress, setProgress] = useState({ completed: 0, total: 0 });

  // Función para actualizar los parámetros de la consulta
  const updateQueryParams = (newParams) => {
    setQueryParams((prevParams) => ({
      ...prevParams,
      ...newParams,
    }));
  };

  // Función para agregar una nueva consulta
  const addQuery = (newQuery) => {
    setQueries((prevQueries) => [...prevQueries, newQuery]);
  };

  const updateQuery = (updatedQuery) => {
    setQueries((prevQueries) => {
      return prevQueries.map((query) => (query.queryUUID === updatedQuery.queryUUID ? updatedQuery : query));
    });
  };

  const refreshStatusQuery = (updatedQuery) => {
    setQueries((prevQueries) => {
      return prevQueries.map((query) =>
        query.queryUUID === updatedQuery.queryUUID ? { ...query, ...updatedQuery } : query
      );
    });
  };

  // Función para eliminar una consulta por su UUID
  const removeQuery = (queryUUID) => {
    setQueries((prevQueries) => prevQueries.filter((query) => query.queryUUID !== queryUUID));
  };

  const getSelectedQuery = () => {
    const index = queries.findIndex((query) => query.queryUUID === selectedQueryId);
    if (index === -1) return null; // Si no se encuentra, retorna null
    return { ...queries[index], row_index: index + 1 };
  };

  // Función para cargar queries desde la hoja DataslayerQueries
  const loadQueriesFromSheet = async (setIsLoading) => {
    try {
      if (setIsLoading) setIsLoading(true);
      await Excel.run(async (context) => {
        const sheetName = "DataslayerQueries";
        let sheet = context.workbook.worksheets.getItemOrNullObject(sheetName);

        await context.sync();

        if (sheet.isNullObject) {
          if (setIsLoading) setIsLoading(false);
          return;
        }

        const usedRange = sheet.getUsedRange();
        usedRange.load("values");
        await context.sync();

        const rows = usedRange.values;
        const loadedQueries = rows.slice(1).map((row) => ({
          queryUUID: row[0] || "",
          sheetName: row[1] || "",
          rangeAddress: row[2] || "",
          created: row[3] || "",
          updated: row[4] || "",
          lastStatus: row[5] || "",
          lastRefreshID: row[6] || "",
          resultsContainSampledData: row[7] || "",
          executionTime: row[8] || "",
          objectType: row[9] || "",
          linkedChartID: row[10] || "",
          dataSource: row[11] || "",
          dateRangeType: row[12] || "",
          startDate: row[13] || "",
          endDate: row[14] || "",
          compareTo: row[15] || "",
          comparisonValueType: row[16] || "",
          accountsViews: row[17] || "",
          metrics: row[18] || "",
          dimensions: row[19] || "",
          pivotDimensions: row[20] || "",
          filters: row[21] || "",
          segmentID: row[22] || "",
          segmentDim: row[23] || "",
          sort: row[24] || "",
          maxRows: row[25] || "",
          maxPivotCategories: row[26] || "",
          specialSettings: row[27] || "",
          otherParameters: row[28] || "",
          resultType: row[29] || "",
          languageCountry: row[30] || "",
          translateTo: row[31] || "",
          sql: row[32] || "",
          databaseName: row[33] || "",
          rangeAddressStatic: row[34] || "",
          reportType: row[35] || "",
          highlightWithColour: row[36] || "",
          refreshWithUserAccount: row[37] || "",
          returnedData: row[38] || "",
          sortDimensions: row[39] || "",
          createdByUserAccount: row[40] || "",
          blendName: row[41] || "",
        }));
        setQueries(loadedQueries);
      });
    } catch (error) {
      console.error("Error cargando las consultas desde Excel:", error);
    } finally {
      if (setIsLoading) setIsLoading(false);
    }
  };

  useEffect(() => {
    loadQueriesFromSheet();
  }, []);

  useEffect(() => {
    window.globalQueries = queries;
  }, [queries]);

  return (
    <QueryContext.Provider
      value={{
        queryParams,
        updateQueryParams,
        queries,
        addQuery,
        removeQuery,
        updateQuery,
        loadQueriesFromSheet,
        getSelectedQuery,
        selectedQueryId,
        setSelectedQueryId,
        refreshStatusQuery,
        setIsDuplicating,
        isDuplicating,
        progress,
        setProgress,
        isEditing,
        setIsEditing,
      }}
    >
      {children}
    </QueryContext.Provider>
  );
};
